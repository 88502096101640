import { Text, TextProps } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { COLOR } from '@/src/constants';

export const TextLink = ({ children, to, ...rest }: PropsWithChildren<TextProps & { to: string }>) => (
  <Text color={COLOR.text.link} _hover={{ textDecoration: 'underline' }} {...rest}>
    <Link to={to}>{children}</Link>
  </Text>
);

export const TextButton = styled.span`
  cursor: pointer;
  color: ${COLOR.text.link};
  font-size: 12px;
  &:hover {
    text-decoration: underline;
  }
`;
