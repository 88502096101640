import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '@/src/common/hooks/useCurrentUser';

export const UnSignedGuard = ({ component }: { component: React.ReactNode }) => {
  const navigate = useNavigate();

  const { currentUser } = useCurrentUser();
  useEffect(() => {
    if (currentUser) navigate('/');
  }, [currentUser, navigate]);

  return <>{component}</>;
};
