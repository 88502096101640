import { KaminariPageInfoFragment, useNewReviewsQuery } from '@/graphql/generated';
import { Pagination } from '@/src/common/components/Pagination/Pagination';
import { COLOR } from '@/src/constants';
import { usePageInfo } from '@/src/common/hooks/usePageInfo';
import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { animateScroll } from 'react-scroll';

export const NewReviewsPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [pageInfo, setPageInfo, setPage] = usePageInfo();
  useEffect(() => {
    const page = Number(searchParams.get('page') ?? 1);
    setPage(page);
  }, [searchParams, setPage]);

  const [{ data, fetching, error }] = useNewReviewsQuery({ variables: { page: pageInfo.currentPage } });

  useEffect(() => {
    const newPageInfo: KaminariPageInfoFragment | undefined = data?.newReviews?.pageInfo;
    setPageInfo(newPageInfo, fetching);
    const newPage = newPageInfo?.currentPage?.toString();
    if (!newPage) return;

    setSearchParams(newPage === '1' ? {} : { page: newPage });
  }, [data, fetching, setPageInfo, params.recommend_type, setSearchParams]);

  if (error) throw new Error(error.message);

  return (
    <>
      <Heading as="h2">新着レビュー一覧</Heading>
      <Box>
        {data?.newReviews?.nodes?.map((review) => (
          <Flex key={review.id} bg={COLOR.background.pale} borderRadius="4px" p="12px" mb="32px">
            <Box width="100px" flexShrink="0" onClick={() => navigate(`/movies/${review.movie.id}`)} cursor="pointer">
              <Image
                width="100%"
                height="auto"
                alt={review.movie.title}
                src={
                  review.movie.posterPath
                    ? `https://image.tmdb.org/t/p/original${review.movie.posterPath}`
                    : 'https://placehold.jp/30/3d4070/ffffff/272x408.png?text=no+image'
                }
                sx={{
                  objectFit: 'cover',
                  aspectRatio: '272 / 408',
                }}
              />
            </Box>

            <Box ml="12px" flexGrow="1">
              <Text fontSize="10px" color={COLOR.text.deep}>
                {DateTime.fromISO(review.createdAt as string).toFormat('yyyy/MM/dd')}
              </Text>
              <Text mt="10px" fontSize="13px" fontWeight="600">
                {review.title}
              </Text>
              <Text
                mt="10px"
                fontSize="10px"
                color={COLOR.text.middle}
                onClick={() => navigate(`/user_reviews/${review.user.id}`)}
                cursor="pointer"
              >
                {review.user.name}
              </Text>
              <Text mt="10px" fontSize="12px">
                {review.totalScore}
              </Text>
              <ReadMoreText>{review.reviewText}</ReadMoreText>
            </Box>
          </Flex>
        ))}
      </Box>
      <Flex className="flex justify-center">
        <Pagination
          pageInfo={pageInfo}
          onChange={(newPage: number) => {
            animateScroll.scrollToTop({ duration: 100 });
            setPage(newPage);
          }}
        />
      </Flex>
    </>
  );
};

function ReadMoreText({
  lineHeight = 10,
  maxLine = 3,
  children,
}: PropsWithChildren<{ lineHeight?: number; maxLine?: number }>) {
  const textRef = useRef<HTMLParagraphElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isClamp, setIsClamp] = useState(false);

  useEffect(() => {
    if (textRef.current === null) return;

    const elementHeight = textRef.current.clientHeight;
    const expectedHeight = lineHeight * maxLine;
    if (elementHeight > expectedHeight) {
      setIsClamp(true);
    }
  }, [children, lineHeight, maxLine]);

  const toggleLines = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box mt="12px" fontSize="10px" color={COLOR.text.middle}>
      <Text
        ref={textRef}
        sx={
          isExpanded
            ? {}
            : {
                display: '-webkit-box',
                '-webkit-box-orient': 'vertical',
                '-webkit-line-clamp': '3',
                overflow: 'hidden',
                'text-overflow': 'ellipsis',
              }
        }
      >
        {children}
      </Text>
      {isClamp && (
        <Text onClick={toggleLines} color={COLOR.text.link} textAlign="right">
          {isExpanded ? '閉じる' : '続きを読む'}
        </Text>
      )}
    </Box>
  );
}
