import { Box, Flex, Heading } from '@chakra-ui/react';
import { faBars, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

import { useSignOutMutation } from '@/graphql/generated';
import { SimpleButton, TextLink } from '@/src/common/components';
import { Drawer } from '@/src/layouts/Drawer';
import { SearchBar } from '@/src/layouts/SearchBar';
import { COLOR, FONT_SIZE, TITLE } from '@/src/constants';
import { useCurrentUser } from '@/src/common/hooks/useCurrentUser';
import { useDisclosure } from '@/src/common/hooks/useDisclosure';

export const Header = () => {
  const { currentUser } = useCurrentUser();
  const { isOpen: isMenuOpen, onOpen: onMenuOpen, onClose: onMenuClose } = useDisclosure();
  const { isOpen: isSearchOpen, onOpen: onSearchOpen, onClose: onSearchClose } = useDisclosure();

  return (
    <>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100vw"
        paddingX="24px"
        paddingY="12px"
      >
        <Link to="/">
          <Heading as="h1" color={COLOR.text.base} fontSize={FONT_SIZE.title.title1} fontWeight="700">
            {TITLE}
          </Heading>
        </Link>
        <Flex flexDirection="row" justifyContent="center" alignItems="center">
          {!currentUser && (
            <Box marginRight="12px">
              <Link to="/users/sign_up">
                <SimpleButton color="header" fontSize="lg" paddingX="24px" paddingY="12px">
                  新規登録
                </SimpleButton>
              </Link>
            </Box>
          )}
          <Box marginRight="12px">
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              fontSize={FONT_SIZE.button.lg}
              cursor="pointer"
              onClick={onSearchOpen}
            />
          </Box>
          <Box>
            <FontAwesomeIcon icon={faBars} fontSize={FONT_SIZE.button.lg} cursor="pointer" onClick={onMenuOpen} />
          </Box>
        </Flex>
      </Flex>
      <SearchBar isOpen={isSearchOpen} onClose={onSearchClose} />
      <Menu isLoggedIn={!!currentUser} isOpen={isMenuOpen} onClose={onMenuClose} />
    </>
  );
};

const Menu = ({ isLoggedIn, isOpen, onClose }: { isLoggedIn: boolean; isOpen: boolean; onClose: () => void }) => {
  const [_, mutateLogout] = useSignOutMutation();

  const handleLogout = () => {
    onClose();
    mutateLogout({ input: {} });
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} title="メニュー">
      {isLoggedIn ? (
        <>
          <TextLink to="/my_reviews" onClick={onClose}>
            マイページ
          </TextLink>
          <TextLink to="/account" onClick={onClose}>
            アカウント
          </TextLink>
          <TextLink to="/profile" onClick={onClose}>
            プロフィール
          </TextLink>
          <TextLink to="#" onClick={handleLogout}>
            ログアウト
          </TextLink>
        </>
      ) : (
        <>
          <TextLink to="/users/sign_in" onClick={onClose}>
            ログイン
          </TextLink>
          <TextLink to="/users/sign_up" onClick={onClose}>
            新規登録
          </TextLink>
        </>
      )}
    </Drawer>
  );
};
