import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AdvancedSearchMoviesQueryVariables, useAdvancedSearchMoviesQuery } from '@/graphql/generated';
import { Container, Loading, NoData } from '@/src/common/components';
import { Title } from '@/src/layouts/Title';
import { ReturnLink } from '@/src/common/components/Link/ReturnLink';
import { MovieList } from '@/src/features/main/components/MovieList';

export const AdvancedSearchMoviesPage = () => {
  const [searchParams] = useSearchParams();
  const withGenres = searchParams.get('genres');
  const variables = useMemo(
    () => (withGenres ? { withGenres, page: Number(searchParams.get('page') ?? 1) } : undefined),
    [withGenres, searchParams]
  );

  return (
    <Container>
      <ReturnLink to="/" />
      {variables && <SearchMovieResult variables={variables} />}
    </Container>
  );
};

const SearchMovieResult = ({ variables }: { variables: AdvancedSearchMoviesQueryVariables }) => {
  const [{ data, fetching: loading, error }] = useAdvancedSearchMoviesQuery({
    variables,
  });
  const genres = useMemo(
    () => (data ? data.movieGenreKindsByWithGenres.map((genre) => genre.name).join(',') : undefined),
    [data]
  );

  if (error) {
    console.error(error);
    return <>{error}</>;
  }
  if (loading) return <Loading />;
  if (!data?.advancedSearchMovies) return <NoData />;

  return (
    <>
      <Title title={genres} />
      <p>ジャンル: {genres}</p>
      <MovieList movies={data.advancedSearchMovies} />
    </>
  );
};
