import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React from 'react';
import { AuthProviderKindEnum, useMyAccountQuery } from '@/graphql/generated';
import { Loading, TextLink } from '@/src/common/components';
import { Title } from '@/src/layouts/Title';
import { COLOR } from '@/src/constants';
import { useTranslation } from 'react-i18next';

export const MyAccountPage = () => {
  const { t } = useTranslation();
  const [{ data, fetching: loading, error }] = useMyAccountQuery();

  if (loading) return <Loading />;
  if (error) return <>{error.message}</>;
  if (!data?.currentUser) return <></>;

  return (
    <>
      <Title title="マイアカウント" />
      <SubtitleWrapper className="mt-4">
        <Subtitle>アカウント情報</Subtitle>
        {data.currentUser.email && (
          <SubtitleLinkWrapper>
            <TextLink to="/account/edit">パスワードの変更 ＞</TextLink>
          </SubtitleLinkWrapper>
        )}
      </SubtitleWrapper>
      <p>
        メールアドレス：
        {data.currentUser.email ??
          `Googleアカウント(${
            data.currentUser.authProviders.find((p) => p.kind === AuthProviderKindEnum.Google)?.email ?? ''
          })`}
      </p>
      {data.currentUser.email && <p>パスワード： ****</p>}
      <p>
        性別：
        {data.currentUser.gender ? t(`gender.${data.currentUser.gender}`) : '-'}
      </p>
      <p>
        生年月日：
        {DateTime.fromISO(data.currentUser.birthday as string).toFormat('yyyy年MM月dd日')}
      </p>
    </>
  );
};

const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 18px;
`;

const Subtitle = styled.div`
  color: ${COLOR.grayLight};
`;

const SubtitleLinkWrapper = styled.div`
  // noop
`;
