import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SearchMoviesQueryVariables, useSearchMoviesQuery } from '@/graphql/generated';
import { Container, Loading, NoData } from '@/src/common/components';
import { Title } from '@/src/layouts/Title';
import { ReturnLink } from '@/src/common/components/Link/ReturnLink';
import { MovieList } from '@/src/features/main/components/MovieList';

export const SearchMoviesPage = () => {
  const [searchParams] = useSearchParams();
  const q = searchParams.get('q');
  const variables = useMemo(
    () => (q ? { q, page: Number(searchParams.get('page') ?? 1) } : undefined),
    [q, searchParams]
  );

  return (
    <Container>
      <Title title={q ? `${q} 映画検索` : undefined} />
      <ReturnLink to="/" />
      {variables && <SearchMovieResult variables={variables} />}
    </Container>
  );
};

const SearchMovieResult = ({ variables }: { variables: SearchMoviesQueryVariables }) => {
  const [{ data, fetching: loading, error }] = useSearchMoviesQuery({
    variables,
  });

  if (error) {
    console.error(error);
    return <>{error}</>;
  }
  if (loading) return <Loading />;
  if (!data?.searchMovies) return <NoData />;

  return <MovieList movies={data.searchMovies} />;
};
