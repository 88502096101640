import { sample } from 'lodash';
import React, { useMemo, useState } from 'react';
import { TextLink } from '@/src/common/components';
import { SimpleButton } from '@/src/common/components/Button/SimpleButton';
import { Title } from '@/src/layouts/Title';
import {
  INIT_QUESTION_KEYS,
  QuestionKey,
  QUESTIONS,
} from '@/src/features/main/pages/EmotionDiagnosis/EmotionDiagnosisConstants';

export const EmotionDiagnosisPage = () => {
  const [currentKey, setCurrentKey] = useState<QuestionKey | undefined>(sample(INIT_QUESTION_KEYS));

  const currentQuestion = useMemo(() => (currentKey ? QUESTIONS[currentKey] : undefined), [currentKey]);

  if (!currentQuestion) {
    console.error('invalid currentKey');
    return <>Error...</>;
  }

  return (
    <>
      <Title title="アンケート診断" />
      <h1 className="mb-2">アンケート診断</h1>
      {currentQuestion.type === 'question' ? (
        <div>
          <h2 className="mb-2">質問: {currentQuestion.text}</h2>
          <p className="flex gap-2">
            {currentQuestion.answers.map((answer, index) => (
              <SimpleButton key={index} onClick={() => setCurrentKey(sample(answer.result))}>
                {answer.text}
              </SimpleButton>
            ))}
          </p>
        </div>
      ) : (
        <div>
          <h2>結果</h2>
          <p>
            <TextLink to={`/emotions/${currentKey}`}>{currentQuestion.text}</TextLink>
          </p>
        </div>
      )}
    </>
  );
};
