import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

type Props = {
  title?: string;
  noIndex?: boolean;
  noFollow?: boolean;
};

export const Title = ({ title, noIndex, noFollow }: Props) => {
  const pageTitle = useMemo(() => (title ? `emovie | ${title}` : 'emovie'), [title]);

  const robotsContent = useMemo(() => {
    if (noIndex && noFollow) return 'noindex, nofollow';
    if (noIndex) return 'noindex';
    if (noFollow) return 'nofollow';
  }, [noIndex, noFollow]);

  return (
    <Helmet>
      {pageTitle && <title>{pageTitle}</title>}
      {robotsContent && <meta name="robots" content={robotsContent} />}
    </Helmet>
  );
};
