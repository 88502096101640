import { Box, Center, Flex, Text } from '@chakra-ui/react';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { animateScroll } from 'react-scroll';

import { SimpleButton, TextLink } from '@/src/common/components';
import { COLOR, FONT_SIZE, TITLE } from '@/src/constants';
import { useCurrentUser } from '@/src/common/hooks/useCurrentUser';

export const Footer = () => {
  const { currentUser } = useCurrentUser();

  return (
    <Flex flexDirection="column" alignItems="center" width="100%" backgroundColor={COLOR.background.deep}>
      <ScrollToTop />

      <Center fontSize="30px" marginBottom="16px">
        {TITLE}
      </Center>
      {!currentUser && (
        <Center width="100%" maxWidth="400px" marginBottom="20px">
          <Link to="/users/sign_up">
            <SimpleButton color="secondary" radius="xl" width="100%" paddingY="14px">
              新規登録
            </SimpleButton>
          </Link>
        </Center>
      )}
      <Center marginBottom="16px">
        <TextLink to="#">私達について</TextLink>
        <Text marginX="10px"> | </Text>
        <TextLink to="#">お問い合わせ</TextLink>
      </Center>
      <Center marginBottom="29px">
        <TextLink to="#">プライバシーポリシー</TextLink>
        <Text marginX="10px"> | </Text>
        <TextLink to="#">利用規約</TextLink>
      </Center>
    </Flex>
  );
};

const ScrollToTop = () => (
  <Box width="100%">
    <Center
      paddingY="14px"
      marginBottom="16px"
      cursor="pointer"
      onClick={animateScroll.scrollToTop}
      _hover={{ backgroundColor: COLOR.background.pale }}
    >
      <Text marginRight="5px">ページトップ</Text>
      <FontAwesomeIcon icon={faChevronUp} fontSize={FONT_SIZE.button.lg} cursor="pointer" />
    </Center>
  </Box>
);
