import styled from '@emotion/styled';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { TextLink } from '@/src/common/components';

type Props = {
  to?: string;
};

export const ReturnLink = ({ to }: Props) => {
  const navigate = useNavigate();

  return (
    <ReturnLinkWrapper>
      <TextLink to={to ?? '#'} onClick={to ? undefined : () => navigate(-1)}>
        ＜ 戻る
      </TextLink>
    </ReturnLinkWrapper>
  );
};

const ReturnLinkWrapper = styled.div`
  margin-bottom: 16px;
`;
