import React, { CSSProperties, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { rgba } from 'emotion-rgba';

import { COLOR } from '@/src/constants';
import { Box, Flex, Heading } from '@chakra-ui/react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title?: string | ReactNode;
};

const config = {
  // tension: 1000,
  // friction: 10,
};

export const Drawer = ({ isOpen, onClose, title, children }: PropsWithChildren<Props>) => {
  const springBackdrop = useSpring(
    isOpen
      ? {
          display: 'block',
          opacity: 1,
        }
      : {
          display: 'none',
          opacity: 0,
        }
  );

  const show = {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '300px',
    height: '100vh',
    transition: 'all 0.1s linear 0s',
    transform: 'translateX(0%)',
  };
  const hide = {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '300px',
    height: '100vh',
    transition: 'all 0.1s linear 0s',
    transform: 'translateX(100%)',
  };
  // @ts-ignore
  const springSidePanel: CSSProperties = useSpring(
    isOpen
      ? {
          to: show,
          from: hide,
          config,
        }
      : {
          to: hide,
          from: show,
          config,
        }
  );
  const [isOpenDelay, setIsOpenDelay] = useState(false);
  useEffect(() => {
    if (isOpen) {
      setIsOpenDelay(true);
    } else {
      setTimeout(() => setIsOpenDelay(false), 1000);
    }
  }, [setIsOpenDelay, isOpen]);

  return (
    <>
      <nav>
        <animated.div style={springBackdrop}>
          <Box
            position="fixed"
            top={0}
            right={0}
            left={0}
            bottom={0}
            bg={rgba(COLOR.black, 0.25)}
            onClick={onClose}
            zIndex={998}
          />
        </animated.div>

        <Box
          display={isOpenDelay ? 'block' : 'none'}
          position="absolute"
          top={0}
          right="300px"
          width={0}
          height={0}
          zIndex={999}
        >
          <Box position="relative" overflowX="hidden" width="300px" height="100%" minHeight="100vh">
            <animated.div style={springSidePanel}>
              <Flex
                width="100%"
                height="100%"
                bgColor={COLOR.background.pale}
                color={COLOR.white}
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                padding="10px"
              >
                <Heading as="h3" color={COLOR.white}>
                  {title}
                </Heading>
                {children}
              </Flex>
            </animated.div>
          </Box>
        </Box>
      </nav>
    </>
  );
};
