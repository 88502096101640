import React, { useEffect, useState } from 'react';
import { animateScroll } from 'react-scroll';
import { useMypageQuery } from '@/graphql/generated';
import { Loading, TextLink } from '@/src/common/components';
import { Title } from '@/src/layouts/Title';
import { Pagination } from '@/src/common/components/Pagination/Pagination';
import { usePageInfo } from '@/src/common/hooks/usePageInfo';
import MoviePosterNoImage from '@/src/images/movie_poster_no_image.png';

export const MyPage = () => {
  const [tab, setTab] = useState<'reviews' | 'bookmarks'>('reviews');
  const [reviewsPageInfo, setReviewsPageInfo, setReviewsPage] = usePageInfo();
  const [bookmarksPageInfo, setBookmarksPageInfo, setBookmarksPage] = usePageInfo();
  const [{ data, fetching: loading, error }] = useMypageQuery({
    variables: {
      reviewsPage: reviewsPageInfo.currentPage,
      bookmarksPage: bookmarksPageInfo.currentPage,
    },
  });
  useEffect(() => {
    setReviewsPageInfo(data?.currentUserReviews?.pageInfo, loading);
    setBookmarksPageInfo(data?.currentUserMovieBookmarks?.pageInfo, loading);
  }, [data, loading, setReviewsPageInfo, setBookmarksPageInfo]);

  if (error) return <>{error}</>;

  return (
    <>
      <Title title="マイページ" />
      <div className="mb-4">
        <button
          className={
            tab === 'reviews'
              ? 'hover:bg-gray-100 text-white font-semibold py-2 px-4'
              : 'bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 mx-1 px-4 border border-gray-400 rounded'
          }
          onClick={() => setTab('reviews')}
        >
          レビューした映画一覧
        </button>
        <button
          className={
            tab === 'bookmarks'
              ? 'hover:bg-gray-100 text-white font-semibold py-2 px-4'
              : 'bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 mx-1 px-4 border border-gray-400 rounded'
          }
          onClick={() => setTab('bookmarks')}
        >
          見たい映画
        </button>
      </div>
      {tab === 'reviews' && (
        <>
          {loading ? (
            <Loading />
          ) : (
            <ul>
              {data?.currentUserReviews?.nodes?.map((review) => {
                if (!review) return <></>;
                return (
                  <li key={review.id} className="mb-3">
                    <img
                      width="200"
                      src={
                        review.movie.posterPath
                          ? `https://image.tmdb.org/t/p/original${review.movie.posterPath}`
                          : MoviePosterNoImage
                      }
                    />
                    {review.movie.id ? (
                      <>
                        <TextLink to={`/movies/${review.movie.id}`}>{review.movie.title}</TextLink>
                      </>
                    ) : (
                      <>{review.movie.title}</>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
          <div className="flex justify-center">
            <Pagination
              pageInfo={reviewsPageInfo}
              onChange={(newPage: number) => {
                animateScroll.scrollToTop({ duration: 100 });
                setReviewsPage(newPage);
              }}
            />
          </div>
        </>
      )}

      {tab === 'bookmarks' && (
        <>
          {loading ? (
            <Loading />
          ) : (
            <ul>
              {data?.currentUserMovieBookmarks?.nodes?.map((bookmark) => (
                <li key={bookmark.movie.id} className="mb-3">
                  <img
                    width="200"
                    src={
                      bookmark.movie.posterPath
                        ? `https://image.tmdb.org/t/p/original${bookmark.movie.posterPath}`
                        : MoviePosterNoImage
                    }
                  />
                  <TextLink to={`/movies/${bookmark.movie.id}`}>{bookmark.movie.title}</TextLink>
                </li>
              ))}
            </ul>
          )}
          <div className="flex justify-center">
            <Pagination
              pageInfo={bookmarksPageInfo}
              onChange={(newPage: number) => {
                animateScroll.scrollToTop({ duration: 100 });
                setBookmarksPage(newPage);
              }}
            />
          </div>
        </>
      )}
    </>
  );
};
