import React from 'react';

export const StarOutlined = ({ color = '#FFE03F', w = 14, h = 13 }: { color?: string; w?: number; h?: number }) => (
  <svg width={w} height={h} viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 9.28125L9.5 10.7812L8.84375 7.9375L11.0625 6L8.125 5.75L7 3.0625L5.875 5.75L2.9375 6L5.15625 7.9375L4.5 10.7812L7 9.28125ZM13.6562 5.15625L10.0312 8.3125L11.125 13L7 10.5L2.875 13L3.96875 8.3125L0.34375 5.15625L5.125 4.75L7 0.34375L8.875 4.75L13.6562 5.15625Z"
      fill={color}
    />
  </svg>
);
