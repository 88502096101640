import { Box, Flex, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TextButton } from '@/src/common/components';
import { COLOR } from '@/src/constants';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const SearchBar = ({ isOpen, onClose }: Props) => {
  const navigate = useNavigate();
  const [inputTitle, setInputTitle] = useState('');

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSearch();
  };
  const onSearch = () => {
    onClose();
    if (inputTitle) navigate(`/search?q=${inputTitle}`);
  };

  if (!isOpen) return <></>;

  return (
    <Box position="relative" width="100%">
      <Flex
        position="absolute"
        width="100%"
        justifyContent="center"
        alignItems="center"
        padding="16px"
        gap="16px"
        backgroundColor={COLOR.background.pale}
      >
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <InputGroup>
            <InputLeftElement pointerEvents="none" paddingLeft="10px" top="50%" transform="translateY(-50%)">
              <FontAwesomeIcon icon={faMagnifyingGlass} fontSize="16px" />
            </InputLeftElement>
            <Input
              name="q"
              type="text"
              placeholder="映画タイトル"
              backgroundColor={COLOR.background.form}
              width="100%"
              paddingY="12px"
              paddingLeft="32px"
              paddingRight="16px"
              borderRadius="3px"
              value={inputTitle}
              onChange={(e) => setInputTitle(e.target.value)}
              autoFocus
              _placeholder={{
                color: COLOR.text.placeholder,
              }}
            />
          </InputGroup>
        </form>
        <CancelTextButton onClick={onClose}>キャンセル</CancelTextButton>
      </Flex>
    </Box>
  );
};

const CancelTextButton = styled(TextButton)`
  white-space: nowrap;
  width: 60px;
`;
