import { COLOR } from '@/src/constants';
import { StarOutlined, Star } from '@/src/images';
import React from 'react';
import Rating from 'react-rating';

type Props = {
  rating: number;
  onChange?: (rating: number) => void;
  color?: 'primary' | 'secondary';
  w?: number;
  h?: number;
};

export const StarRating = ({ rating, onChange, color = 'primary', w = 14, h = 13 }: Props) => {
  return (
    <Rating
      initialRating={rating} // NOTE: initialとあるが、後から変更した値も反映される
      emptySymbol={<StarOutlined w={w} h={h} color={color === 'primary' ? undefined : COLOR.text.middle} />}
      fullSymbol={<Star w={w} h={h} color={color === 'primary' ? undefined : COLOR.theme.secondary} />}
      onChange={onChange}
      readonly={!onChange}
      fractions={10} // NOTE: 10分割(0.1刻み)で評価
    />
  );
};
