import Highcharts, { Options } from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import React, { useMemo } from 'react';
import { COLOR } from '@/src/constants';
import { Flex } from '@chakra-ui/react';
type Evaluation = {
  scenarioScore?: number | null;
  directionScore?: number | null;
  musicScore?: number | null;
  videoScore?: number | null;
  actionScore?: number | null;
};

type Props = {
  evaluation?: Evaluation | null;
};

HighchartsMore(Highcharts);

export const EvaluationChart = ({ evaluation }: Props) => {
  const options: Options | undefined = useMemo(() => {
    const seriesData = [
      evaluation?.scenarioScore ?? null,
      evaluation?.directionScore ?? null,
      evaluation?.musicScore ?? null,
      evaluation?.videoScore ?? null,
      evaluation?.actionScore ?? null,
    ];

    if (seriesData.every((score) => score === null)) return;

    const res: Options = {
      chart: {
        polar: true,
        type: 'line',
        backgroundColor: 'transparent',
      },

      accessibility: {
        description: '説明',
      },

      title: {
        text: '',
        x: 0,
      },

      pane: {
        size: '80%',
      },

      xAxis: {
        categories: ['脚本', '演出', '音楽', '映像', '演技'],
        tickmarkPlacement: 'on',
        lineWidth: 0,
        labels: {
          align: 'center',
          distance: 20,
          style: { color: COLOR.text.middle, fontSize: '12px' },
        },
        gridLineColor: 'transparent', // 中心から各頂点への線の色
      },

      yAxis: {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        // 0~5の評価
        min: 0,
        max: 5,
        labels: {
          enabled: false, // 0~5の数値を表示するか
        },
        tickPositions: [1, 2, 3, 4, 5],
        showLastLabel: true,
      },

      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        enabled: false,
      },

      series: [
        {
          type: 'area', // NOTE: 塗りつぶし
          name: '評価項目',
          data: seriesData,
          pointPlacement: 'on',
          color: 'transparent',
          fillColor: COLOR.theme.secondary,
        },
      ],

      plotOptions: {
        series: {
          dataLabels: {
            enabled: false, // NOTE: 各頂点に数値表示するか
          },
        },
      },
      credits: {
        enabled: false,
      },

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 1,
            },
            chartOptions: {
              legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal',
              },
            },
          },
        ],
      },
    };
    return res;
  }, [evaluation]);

  if (!options) return <></>;

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      p={0}
      m={0}
      sx={{
        '.highcharts-yaxis-grid .highcharts-grid-line': {
          fill: COLOR.background.pale,
          stroke: 'transparent',
          strokeWidth: '0px',
        },
      }}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        containerProps={{
          style: {
            height: 200,
            width: 200,
          },
        }}
      />
    </Flex>
  );
};
