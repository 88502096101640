import { MovieDetailQuery, UpdateReviewInput, useDeleteReviewMutation } from '@/graphql/generated';
import { SimpleButton, SimpleOutlineButton } from '@/src/common/components';
import { RequiredLabel } from '@/src/common/components/Label/RequiredLabel';
import { ConfirmModal } from '@/src/common/components/Modal/ConfirmModal';
import { CustomModal } from '@/src/common/components/Modal/CustomModal';
import { COLOR } from '@/src/constants';
import { ALL_EMOTIONS, DETAIL_SCORES, EMOTION } from '@/src/constants';
import { useDisclosure } from '@/src/common/hooks/useDisclosure';
import { CircleCheck } from '@/src/images';
import { useUpdateReview } from '@/src/features/main/pages/MovieDetail/hooks/useUpdateReview';
import { StarRating } from '@/src/common/components/StarRating/StarRating';
import { Center, Flex, Heading, Image, Input, InputProps, Text, Textarea } from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import React, { forwardRef, useCallback } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';

export const EditReview = ({
  movieId,
  movieTitle,
  currentUserReview,
}: {
  movieId: string;
  movieTitle: string;
  currentUserReview: MovieDetailQuery['currentUserReview'];
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { methods, onUpdateReview, fetching, onReset } = useUpdateReview({
    movieId,
    currentUserReview,
    onSuccess: onClose,
  });

  const actionText = currentUserReview ? '編集' : '投稿';

  return (
    <>
      <SimpleOutlineButton py="21px" width="100%" onClick={onOpen}>
        レビューを{actionText}
      </SimpleOutlineButton>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        title={`レビュー${actionText}`}
        footer={
          <Flex flexDirection="column" w="100%" gap="12px">
            <SimpleButton w="100%" onClick={onUpdateReview} isLoading={fetching} isDisabled={fetching}>
              レビューを{actionText}する
            </SimpleButton>
            <SimpleOutlineButton w="100%" borderColor="transparent" onClick={onClose}>
              キャンセル
            </SimpleOutlineButton>
            {currentUserReview?.id && (
              <DeleteReview
                reviewId={currentUserReview.id}
                onSuccess={() => {
                  onClose();
                  onReset();
                }}
              />
            )}
          </Flex>
        }
      >
        <FormProvider {...methods}>
          <EditReviewBody movieTitle={movieTitle} />
        </FormProvider>
      </CustomModal>
    </>
  );
};

const EditReviewBody = ({ movieTitle }: { movieTitle: string }) => {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<UpdateReviewInput>();

  const title = watch('title');
  const reviewText = watch('reviewText');
  const emotions = watch('emotions');

  return (
    <>
      <Heading as="h2" fontSize="13px" fontWeight="600" color={COLOR.text.base}>
        {movieTitle}
      </Heading>

      <Flex mt="24px" alignItems="center">
        <Text>総合評価</Text>
        <RequiredLabel ml="12px" />
      </Flex>
      <Flex flexDirection="row" mt="12px">
        <StarRating w={25} h={25} rating={watch('totalScore')} onChange={(rating) => setValue('totalScore', rating)} />
        <NumberInput
          {...register('totalScore', {
            setValueAs: (v) => (v ? Number(v) : undefined),
          })}
          isInvalid={!!errors.totalScore}
        />
      </Flex>

      <Flex mt="32px" alignItems="center">
        <Text>詳細評価</Text>
      </Flex>
      {DETAIL_SCORES.map(({ name, label }) => (
        <Flex key={name} mt="8px" justifyContent="space-between" alignItems="center">
          <Text color={COLOR.text.base} fontWeight="600">
            {label}
          </Text>
          <Center>
            <StarRating
              w={23}
              h={23}
              color="secondary"
              rating={watch(name) ?? 0}
              onChange={(rating) => setValue(name, rating)}
            />
            <NumberInput
              {...register(name, {
                setValueAs: (v) => (v ? Number(v) : undefined),
              })}
              isInvalid={!!errors.scenarioScore}
            />
          </Center>
        </Flex>
      ))}

      <Flex mt="24px" alignItems="center">
        <Text>この映画を観たい気分</Text>
        <RequiredLabel ml="12px" />
      </Flex>

      {ALL_EMOTIONS.map((emotion) => {
        const isChecked = emotions.includes(emotion);
        return (
          <Flex
            key={emotion}
            mt="12px"
            borderRadius="5px"
            background={isChecked ? 'rgba(13, 11, 40, 0.5)' : 'rgba(255, 255, 255, 0.2)'}
            color={COLOR.white}
            padding="10px"
            _hover={{
              background: isChecked ? 'rgba(13, 11, 40, 0.2)' : 'rgba(255, 255, 255, 0.1)',
            }}
            justifyContent="space-between"
            alignItems="center"
            cursor="pointer"
            onClick={() =>
              setValue(`emotions`, isChecked ? emotions.filter((e) => e !== emotion) : [...emotions, emotion])
            }
          >
            <Center>
              <Image width="26px" height="26px" src={EMOTION[emotion].icon} alt={EMOTION[emotion].text} />
              <Text ml="8px" color={COLOR.text.middle} fontSize="12px" fontWeight="600">
                {EMOTION[emotion].text}
              </Text>
            </Center>
            <CircleCheck isChecked={isChecked} />
          </Flex>
        );
      })}
      <ErrorMessage errors={errors} name="recommendPositive" render={ErrorText} />

      <Flex mt="32px" alignItems="center">
        <Text>タイトル</Text>
      </Flex>

      <Input
        type="text"
        placeholder="タイトル"
        fontSize="12px"
        color={COLOR.background.bright}
        _placeholder={{ color: COLOR.text.placeholder }}
        bg={COLOR.background.form}
        borderColor="transparent"
        errorBorderColor={COLOR.status.attention}
        mt="12px"
        maxLength={40}
        {...register('title')}
        isInvalid={!!errors.title}
      />
      <Text fontSize="12px" color={errors.title ? 'red' : COLOR.text.middle} mt="8px" textAlign="right">
        {title.length}/40文字以内
      </Text>

      <Flex mt="32px" alignItems="center">
        <Text>本文</Text>
      </Flex>

      <Textarea
        {...register('reviewText')}
        fontSize="12px"
        color={COLOR.background.bright}
        _placeholder={{ color: COLOR.text.placeholder }}
        bg={COLOR.background.form}
        borderColor="transparent"
        errorBorderColor={COLOR.status.attention}
        mt="12px"
        placeholder="映画のレビューを入力"
        maxLength={4000}
      ></Textarea>
      <Text fontSize="12px" color={errors.title ? 'red' : COLOR.text.middle} mt="8px" textAlign="right">
        {reviewText.length}/4000文字以内
      </Text>
    </>
  );
};

const DeleteReview = ({ reviewId, onSuccess }: { reviewId: string; onSuccess: () => void }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [{ fetching, error }, deleteReview] = useDeleteReviewMutation();

  const onDelete = useCallback(async () => {
    await deleteReview({ input: { id: reviewId } }, { additionalTypenames: ['ReviewSummary'] });
    onClose();
    onSuccess();
  }, [deleteReview, reviewId, onClose, onSuccess]);

  if (error) throw Error(error.message);

  return (
    <>
      <SimpleButton color="danger" w="100%" onClick={onOpen}>
        削除
      </SimpleButton>
      <ConfirmModal
        isOpen={isOpen}
        onExec={onDelete}
        onClose={onClose}
        title="レビューの削除"
        execText="削除する"
        variant="danger"
        isLoading={fetching}
      >
        レビューを削除すると元に戻すことは出来なくなります。
        <br />
        本当にレビューを削除してもよろしいですか？
      </ConfirmModal>
    </>
  );
};

const ErrorText = ({ message }: { message: string }) => (
  <Text color="red" mt="8px" fontSize="10px">
    {message}
  </Text>
);

const NumberInput = forwardRef<HTMLInputElement, InputProps>(({ ...inputProps }, ref) => (
  <Input
    ref={ref}
    type="number"
    min="0"
    max="5"
    step="0.1"
    w="48px"
    h="27px"
    ml="12px"
    px="0"
    textAlign="center"
    fontSize="12px"
    color={COLOR.text.base}
    bg={COLOR.background.form}
    borderColor="transparent"
    errorBorderColor={COLOR.status.attention}
    {...inputProps}
  />
));
NumberInput.displayName = 'NumberInput';
