import { MovieDetailQuery, UpdateReviewInput, useUpdateReviewMutation } from '@/graphql/generated';
import { ALL_EMOTIONS } from '@/src/constants';
import { PartialBy } from '@/src/types/global';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useMemo } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { z } from 'zod';

export const useUpdateReview = ({
  movieId,
  currentUserReview,
  onSuccess,
}: {
  movieId: string;
  currentUserReview: MovieDetailQuery['currentUserReview'];
  onSuccess: () => void;
}) => {
  const defaultValues: PartialBy<UpdateReviewInput, 'totalScore'> = useMemo(() => {
    if (!currentUserReview) return { ...DEFAULT_VALUES, movieId };

    return {
      movieId,
      title: currentUserReview.title,
      reviewText: currentUserReview.reviewText,

      totalScore: currentUserReview.totalScore,
      actionScore: currentUserReview.actionScore ?? undefined,
      directionScore: currentUserReview.directionScore ?? undefined,
      musicScore: currentUserReview.musicScore ?? undefined,
      scenarioScore: currentUserReview.scenarioScore ?? undefined,
      videoScore: currentUserReview.videoScore ?? undefined,

      emotions: currentUserReview.emotions,
    };
  }, [movieId, currentUserReview]);

  const methods = useForm<UpdateReviewInput>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const [{ fetching }, updateReview] = useUpdateReviewMutation();
  const onValid = useCallback(
    async (input: UpdateReviewInput) => {
      if (fetching) return;

      const { data } = await updateReview({ input }, { additionalTypenames: ['ReviewSummary'] });
      if (data?.updateReview?.review.id) {
        onSuccess();
      }
    },
    [updateReview, fetching, onSuccess]
  );
  const onInValid = useCallback((error: FieldErrors<UpdateReviewInput>) => {
    console.error(error);
  }, []);

  const onUpdateReview = useCallback(() => {
    methods.handleSubmit(onValid, onInValid)();
  }, [methods, onValid, onInValid]);

  const onReset = useCallback(() => {
    methods.reset({ ...DEFAULT_VALUES, movieId });
  }, [methods, movieId]);

  return {
    defaultValues,
    methods,
    onUpdateReview,
    fetching,
    onReset,
  };
};

const DEFAULT_VALUES: PartialBy<UpdateReviewInput, 'totalScore' | 'movieId'> = {
  title: '',
  reviewText: '',

  totalScore: undefined,
  actionScore: undefined,
  directionScore: undefined,
  musicScore: undefined,
  scenarioScore: undefined,
  videoScore: undefined,

  emotions: [],
};

const schema = z.object({
  movieId: z.string(),
  title: z.string(),
  reviewText: z.string(),

  totalScore: z
    .number({
      required_error: '入力してください',
    })
    .step(0.1)
    .gte(0)
    .lte(5),
  actionScore: z.number().step(0.1).gte(0).lte(5).optional(),
  directionScore: z.number().step(0.1).gte(0).lte(5).optional(),
  musicScore: z.number().step(0.1).gte(0).lte(5).optional(),
  scenarioScore: z.number().step(0.1).gte(0).lte(5).optional(),
  videoScore: z.number().step(0.1).gte(0).lte(5).optional(),

  emotions: z.array(z.enum(ALL_EMOTIONS)).nonempty({
    message: '必ずどれか１つにチェックを入れてください',
  }),
});
