import styled from '@emotion/styled';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import tinycolor from 'tinycolor2';
import { z } from 'zod';
import { ProfileQuery, UpdateProfileInput, useProfileQuery, useUpdateProfileMutation } from '@/graphql/generated';
import { SimpleButton, Loading } from '@/src/common/components';
import { Title } from '@/src/layouts/Title';
import { COLOR } from '@/src/constants';

export const EditProfilePage = () => {
  const [{ data, fetching: loading, error }] = useProfileQuery();

  if (loading) return <Loading />;

  if (error) return <>{error.message}</>;
  if (loading) return <Loading />;
  if (!data?.currentUser) return <></>;

  return <Content data={data} />;
};

const schema = z.object({
  name: z.string().min(1, { message: '名前を入力してください' }),
  profile: z.string().max(100, { message: '100文字以内で入力して下さい' }),
  image: z
    .instanceof(File)
    .nullable()
    .refine((file) => !file || file.size <= 5000000, {
      message: '5MB以下のファイルを指定して下さい',
    }),
  isDeleteImage: z.boolean(),
});

const Content = ({ data }: { data: ProfileQuery }) => {
  const navigate = useNavigate();
  const [{ fetching: loading, error }, mutate] = useUpdateProfileMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<UpdateProfileInput>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: data.currentUser?.name ?? '',
      profile: data.currentUser?.profile ?? '',
      image: null,
      isDeleteImage: false,
    },
  });

  const onSubmit = useCallback(
    async (input: UpdateProfileInput) => {
      if (loading) return;
      const { data: mutateData } = await mutate({ input });
      if (mutateData?.updateProfile?.user?.id) {
        navigate('/profile');
      }
    },
    [mutate, loading, navigate]
  );

  const [previewImage, setPreviewImage] = useState<string | null>(data.currentUser?.image ?? null);

  const onImageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return;
      const reader = new FileReader();
      reader.onload = (evt) => {
        if (!evt.target?.result) return;
        setPreviewImage(evt.target?.result as string);
        setValue('image', file);
        setValue('isDeleteImage', false);
      };
      reader.readAsDataURL(file);
    },
    [setPreviewImage, setValue]
  );

  const onDeleteImage = useCallback(() => {
    setValue('isDeleteImage', true);
    setValue('image', null);
    setPreviewImage(null);
  }, [setPreviewImage, setValue]);

  if (error) return <>{error.message}</>;
  if (loading) return <Loading />;
  if (!data?.currentUser) return <></>;

  return (
    <>
      <Title title="プロフィール" />
      <h1>パスワードの変更</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-1">
          <label htmlFor="name">名前: </label>
        </div>
        <div className="mb-3">
          <Input {...register('name')} id="name" type="text" placeholder="ユーザー名" className="w-full p-3 rounded" />
          <ErrorMessage errors={errors} name="name" render={ErrorText} />
        </div>
        <div className="mb-1">
          <label htmlFor="profile">プロフィール: </label>
        </div>
        <div className="mb-3">
          <TextArea {...register('profile')} id="profile" placeholder="プロフィール" className="w-full p-3 rounded" />
          <ErrorMessage errors={errors} name="profile" render={ErrorText} />
        </div>
        <div className="mb-1">
          <label htmlFor="image">画像: </label>
        </div>
        <div className="mb-3">
          <ButtonLabel htmlFor="image">
            画像を選択
            <input
              id="image"
              type="file"
              accept="image/jpeg, image/png"
              className="w-full p-3 rounded"
              onChange={onImageChange}
              style={{ display: 'none' }}
            />
          </ButtonLabel>

          {previewImage ? (
            <>
              <img src={previewImage} width="100" />
              <SimpleButton onClick={onDeleteImage}>画像を削除</SimpleButton>
            </>
          ) : (
            '未設定'
          )}
          <ErrorMessage errors={errors} name="image" render={ErrorText} />
        </div>
        <div>
          <SimpleButton type="submit">送信</SimpleButton>
        </div>
      </form>
    </>
  );
};

const StyledErrorText = styled.p`
  color: red;
`;
const ErrorText = ({ message }: { message: string }) => <StyledErrorText className="m-2">{message}</StyledErrorText>;

const Input = styled.input`
  max-width: 600px;
  color: ${COLOR.black};
`;

const TextArea = styled.textarea`
  max-width: 600px;
  color: ${COLOR.black};
`;

const ButtonLabel = styled.label`
  padding: 6px 8px;
  border-radius: 10px;
  color: ${COLOR.black};
  font-size: 15px;
  background-color: ${COLOR.gray};
  &:hover {
    background-color: ${tinycolor(COLOR.gray).darken(20).toString()};
  }
`;
