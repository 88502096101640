import { EmotionEnum } from '@/graphql/generated';
import {
  ConsiderationIcon,
  DokidokiIcon,
  ImpressIcon,
  NegativeIcon,
  NostalgiaIcon,
  PositiveIcon,
  QuickIcon,
  SentimentalIcon,
} from '@/src/images';

export const DETAIL_SCORES = [
  { name: 'scenarioScore', label: '脚本' },
  { name: 'actionScore', label: '演技' },
  { name: 'directionScore', label: '演出' },
  { name: 'videoScore', label: '映像' },
  { name: 'musicScore', label: '音楽' },
] as const;

type TEmotion = {
  [key in EmotionEnum]: {
    icon: string;
    text: string;
    link: string;
  };
};

export const EMOTION: TEmotion = {
  positive: { icon: PositiveIcon, text: 'ポジティブな気分', link: '/emotions/positive' },
  negative: { icon: NegativeIcon, text: 'ネガティブな気分', link: '/emotions/negative' },
  impress: { icon: ImpressIcon, text: '感動したい気分', link: '/emotions/impress' },
  quick: { icon: QuickIcon, text: 'さらっと観たい気分', link: '/emotions/quick' },
  sentimental: { icon: SentimentalIcon, text: '繊細な気持ちになりたい気分', link: '/emotions/sentimental' },
  consideration: {
    icon: ConsiderationIcon,
    text: '考えさせられる映画を観たい気分',
    link: '/emotions/consideration',
  },
  nostalgia: { icon: NostalgiaIcon, text: 'ノスタルジーを感じたい気分', link: '/emotions/nostalgia' },
  dokidoki: { icon: DokidokiIcon, text: 'ハラハラドキドキしたい気分', link: '/emotions/dokidoki' },
};

export const ALL_EMOTIONS = [
  EmotionEnum.Positive,
  EmotionEnum.Negative,
  EmotionEnum.Impress,
  EmotionEnum.Quick,
  EmotionEnum.Sentimental,
  EmotionEnum.Consideration,
  EmotionEnum.Nostalgia,
  EmotionEnum.Dokidoki,
] as const;
