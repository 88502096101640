import React, { useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import { KaminariPageInfoFragment, useRecommendMoviesQuery } from '@/graphql/generated';
import { Loading, TextLink } from '@/src/common/components';
import { Title } from '@/src/layouts/Title';
import { Pagination } from '@/src/common/components/Pagination/Pagination';
import { usePageInfo } from '@/src/common/hooks/usePageInfo';
import MoviePosterNoImage from '@/src/images/movie_poster_no_image.png';
import { toEmotion } from '@/src/features/main/functions';
import { EMOTION } from '@/src/constants';

export const EmotionPage = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const emotion = toEmotion(params.emotion);

  const [pageInfo, setPageInfo, setPage] = usePageInfo();
  useEffect(() => {
    const page = Number(searchParams.get('page') ?? 1);
    setPage(page);
  }, [searchParams, setPage]);

  const [{ data, fetching, error }] = useRecommendMoviesQuery({
    variables: {
      emotion: emotion,
      page: pageInfo.currentPage,
    },
  });
  const emotionText = useMemo(() => EMOTION[emotion].text, [emotion]);

  useEffect(() => {
    const newPageInfo: KaminariPageInfoFragment | undefined = data?.recommendMovies?.pageInfo;
    setPageInfo(newPageInfo, fetching as boolean);
    const newPage = newPageInfo?.currentPage?.toString();
    if (!newPage) return;

    setSearchParams(newPage === '1' ? {} : { page: newPage });
  }, [data, fetching, setPageInfo, params.recommend_type, setSearchParams]);

  if (error) return <>{error}</>;

  return (
    <>
      <Title title={emotionText} />
      <h1>{emotionText}</h1>
      {fetching ? (
        <Loading />
      ) : (
        <ul>
          {data?.recommendMovies?.nodes?.map((reviewSummary) => {
            if (!reviewSummary.movie) return <></>;
            return (
              <li key={reviewSummary.movie.id} className="mb-3">
                <img
                  width="200"
                  src={
                    reviewSummary.movie.posterPath
                      ? `https://image.tmdb.org/t/p/original${reviewSummary.movie.posterPath}`
                      : MoviePosterNoImage
                  }
                />
                {reviewSummary.movie.id ? (
                  <>
                    <TextLink to={`/movies/${reviewSummary.movie.id}`}>{reviewSummary.movie.title}</TextLink>
                  </>
                ) : (
                  <>{reviewSummary.movie.title}</>
                )}
              </li>
            );
          })}
        </ul>
      )}
      <div className="flex justify-center">
        <Pagination
          pageInfo={pageInfo}
          onChange={(newPage: number) => {
            animateScroll.scrollToTop({ duration: 100 });
            setPage(newPage);
          }}
        />
      </div>
    </>
  );
};
