import React, { useMemo } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import '@/src/config/i18n';
import { UrqlProvider, initUrqlClient } from '@/lib/urql/urqlClient';
import { Loading } from '@/src/common/components';
import { Routes } from '@/src/routes/Routes';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from '@/lib/urql/chakra/chakraTheme';

export const App: React.FC = () => {
  const [_isForbidden, setIsForbidden] = React.useState(false);

  const urqlClient = useMemo(
    () =>
      initUrqlClient((error) => {
        const isForbiddenError = error.graphQLErrors.some((e) => e.extensions?.code === 'forbidden');
        setIsForbidden(isForbiddenError);
      }),
    [setIsForbidden]
  );

  return (
    <React.Suspense fallback={<Loading />}>
      <UrqlProvider value={urqlClient}>
        <RecoilRoot>
          <HelmetProvider>
            <ChakraProvider theme={theme}>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </ChakraProvider>
          </HelmetProvider>
        </RecoilRoot>
      </UrqlProvider>
    </React.Suspense>
  );
};
