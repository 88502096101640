import { Maybe, ReviewFragment } from '@/graphql/generated';
import { TextLink } from '@/src/common/components';
import { COLOR, DETAIL_SCORES } from '@/src/constants';
import { StarRating } from '@/src/common/components/StarRating/StarRating';
import { Box, Flex, Text, Avatar, useDisclosure, Grid } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// 映画詳細: 映画の情報は不要
// ユーザーのレビュー一覧: ユーザーの情報は不要
// 新着レビュー: 映画の情報・ユーザーの情報ともに必要

export const MovieReview = ({
  review,
  userId,
  userName,
  userImage,
}: {
  review: ReviewFragment;
  userId: string;
  userName: string;
  userImage?: Maybe<string>;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isOpen, onToggle } = useDisclosure();

  const onClickUser = useCallback(() => {
    navigate(`/user_reviews/${userId}`);
  }, [userId, navigate]);

  const hasBasicScore = useMemo(
    () =>
      review.scenarioScore != null ||
      review.actionScore != null ||
      review.directionScore != null ||
      review.videoScore != null ||
      review.musicScore != null,
    [review.scenarioScore, review.actionScore, review.directionScore, review.videoScore, review.musicScore]
  );

  return (
    <Box bg={COLOR.background.pale} borderRadius="4px" p="16px">
      <Flex>
        <Avatar src={userImage ?? undefined} width="40px" height="40px" onClick={onClickUser} />
        <Box ml="12px" w="100%">
          <Flex justifyContent="space-between">
            <Text fontSize="12px" color={COLOR.text.middle} onClick={onClickUser}>
              {userName}
            </Text>
            <Text fontSize="10px" color={COLOR.text.deep}>
              {DateTime.fromISO(review.createdAt as string).toFormat('yyyy/MM/dd')}
            </Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Flex flexDirection="row" justifyContent="start" alignItems="baseline">
              <StarRating rating={review.totalScore} />
              <Text ml="8px" fontSize="12px" color={COLOR.text.base}>
                {review.totalScore.toFixed(1)}
              </Text>
            </Flex>
            {hasBasicScore && (
              <Text fontSize="12px" color={COLOR.text.link} onClick={onToggle}>
                評価詳細を
                {isOpen ? '非表示' : '表示'}
                <Text ml="8px" as="span">
                  {isOpen ? '▲' : '▼' /* TODO: iconにする */}
                </Text>
              </Text>
            )}
          </Flex>
        </Box>
      </Flex>

      {isOpen && (
        <Grid
          mt="12px"
          py="16px"
          px="24px"
          bg={COLOR.background.base}
          borderRadius="8px"
          rowGap="4px"
          columnGap="41px"
          gridTemplateColumns="1fr 1fr"
        >
          {DETAIL_SCORES.map(({ name, label }) => {
            const rating = review[name];
            if (!rating) return <></>;

            return (
              <Flex key={name} flexDirection="row" justifyContent="start" alignItems="baseline">
                <Text fontSize="12px" color={COLOR.text.base}>
                  {label}
                </Text>
                <Box ml="12px">
                  <StarRating rating={rating} color="secondary" />
                </Box>
              </Flex>
            );
          })}
        </Grid>
      )}

      {review.emotions.length > 0 && (
        <Flex mt="12px" flexWrap="wrap" gap="8px">
          {review.emotions.map((emotion) => (
            <TextLink
              display="inline-flex"
              to={`/emotions/${emotion}`}
              key={emotion}
              px="10px"
              py="0"
              fontSize="12px"
              color={COLOR.text.middle}
              bg="rgba(255,255,255,0.2)"
              borderRadius="50px"
              whiteSpace="nowrap"
            >
              {t(`recommend.${emotion}`)}
            </TextLink>
          ))}
        </Flex>
      )}

      <Text mt="16px" fontSize="13px" fontWeight="600">
        {review.title}
      </Text>
      <ReadMoreText maxLine={5}>{review.reviewText}</ReadMoreText>
    </Box>
  );
};

const ReadMoreText = ({ maxLine = 3, children }: PropsWithChildren<{ maxLine?: number }>) => {
  const textRef = useRef<HTMLParagraphElement>(null);
  const { isOpen: isExpanded, onToggle } = useDisclosure();
  const [isClamp, setIsClamp] = useState(false);

  useEffect(() => {
    if (textRef.current === null) return;

    if (textRef.current.scrollHeight > textRef.current.clientHeight) {
      setIsClamp(true);
    }
  }, [children, maxLine]);

  return (
    <Box mt="12px" fontSize="10px" color={COLOR.text.middle}>
      <Text ref={textRef} noOfLines={isExpanded ? undefined : maxLine}>
        {children}
      </Text>
      {isClamp && (
        <Text onClick={onToggle} color={COLOR.text.link} textAlign="right">
          {isExpanded ? '閉じる' : '続きを読む'}
        </Text>
      )}
    </Box>
  );
};

// const ReviewLike = ({
//   reviewId,
//   isLiked,
//   likeCount,
//   isSignedIn,
// }: {
//   reviewId: string;
//   isLiked: boolean;
//   likeCount: number;
//   isSignedIn: boolean;
// }) => {
//   const [{ fetching: loading, error }, mutate] = useUpdateReviewLikeMutation();

//   const onToggleLiked = useCallback(() => {
//     if (!isSignedIn || loading) return;
//     mutate({ input: { reviewId, isLiked: !isLiked } });
//   }, [mutate, loading, isSignedIn, isLiked, reviewId]);

//   if (error) return <>{error}</>;

//   return (
//     <Box onClick={onToggleLiked} cursor="pointer">
//       {isLiked ? '♥' : '♡'}
//       {likeCount}
//     </Box>
//   );
// };
