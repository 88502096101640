import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { usePopularMoviesQuery } from '@/graphql/generated';
import { Container, Loading, NoData } from '@/src/common/components';
import { Title } from '@/src/layouts/Title';
import { ReturnLink } from '@/src/common/components/Link/ReturnLink';
import { MovieList } from '@/src/features/main/components/MovieList';

export const PopularMoviesPage = () => {
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page') ?? 1);
  const variables = useMemo(() => ({ page }), [page]);

  const [{ data, fetching: loading, error }] = usePopularMoviesQuery({
    variables,
  });

  if (error) {
    console.error(error);
    return <>{error}</>;
  }
  if (loading) return <Loading />;
  if (!data?.popularMovies) return <NoData />;

  return (
    <Container>
      <Title title="人気映画" />
      <ReturnLink to="/" />
      <p>人気映画から探す</p>
      <MovieList movies={data.popularMovies} />
    </Container>
  );
};
