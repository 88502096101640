import React from 'react';

export const Star = ({ color = '#FFE03F', w = 14, h = 13 }: { color?: string; w?: number; h?: number }) => (
  <svg width={w} height={h} viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 10.5L2.875 13L3.96875 8.3125L0.34375 5.15625L5.125 4.75L7 0.34375L8.875 4.75L13.6562 5.15625L10.0312 8.3125L11.125 13L7 10.5Z"
      fill={color}
    />
  </svg>
);
