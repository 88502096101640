import styled from '@emotion/styled';
import React from 'react';
import { useProfileQuery } from '@/graphql/generated';
import { Loading, TextLink } from '@/src/common/components';
import { Title } from '@/src/layouts/Title';
import { COLOR } from '@/src/constants';

export const ProfilePage = () => {
  const [{ data, fetching: loading, error }] = useProfileQuery();

  if (error) return <>{error.message}</>;
  if (loading) return <Loading />;
  if (!data?.currentUser) return <></>;

  return (
    <>
      <Title title="プロフィール" />
      <SubtitleWrapper className="mt-4">
        <Subtitle>プロフィール</Subtitle>
        <SubtitleLinkWrapper>
          <TextLink to="/profile/edit">プロフィールの変更 ＞</TextLink>
        </SubtitleLinkWrapper>
      </SubtitleWrapper>
      <p>name: {data.currentUser.name}</p>
      <p>profile: {data.currentUser.profile || '未設定'}</p>
      <p>
        image:
        {data.currentUser.image ? <img src={data.currentUser.image} /> : '未設定'}
      </p>
    </>
  );
};

const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 18px;
`;

const Subtitle = styled.div`
  color: ${COLOR.grayLight};
`;

const SubtitleLinkWrapper = styled.div`
  // noop
`;
