export const FONT_SIZE = {
  title: {
    title1: '17px',
    title2: '15px',
    title3: '13px',
  },
  text: {
    body: '15px',
    caption1: '12px',
    caption2: '10px',
    alert: '12px',
    middle: '12px',
  },
  button: {
    sm: '13px',
    lg: '16px',
  },
  label: {
    regular: {
      sm: '10px',
      md: '12px',
      lg: '14px',
    },
    bold: {
      sm: '10px',
      md: '12px',
      lg: '14px',
    },
  },
} as const;

type ValueOf<T> = T[keyof T];
type NestedValueOf<T> =
  | ValueOf<T>
  | ValueOf<{
      [K in keyof T]: T[K] extends object ? NestedValueOf<T[K]> : never;
    }>;

export type FontSize = Extract<NestedValueOf<typeof FONT_SIZE>, string>;
