import { COLOR } from '@/src/constants';
import React from 'react';

export const CircleCheck = ({ isChecked, size = 25 }: { isChecked: boolean; size?: number }) => {
  const color = isChecked ? COLOR.theme.primary : COLOR.white;
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.5" cy="12.5" r="12.5" fill={color} />
      {isChecked && (
        <path
          d="M10.625 15.1074L17.2461 8.48633L18.125 9.36523L10.625 16.8652L7.13867 13.3789L8.01758 12.5L10.625 15.1074Z"
          fill="white"
        />
      )}
    </svg>
  );
};
