import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { MoviesFragment } from '@/graphql/generated';
import { TextButton, TextLink } from '@/src/common/components';
import { COLOR } from '@/src/constants';
import MoviePosterNoImage from '@/src/images/movie_poster_no_image.png';

type Props = {
  movies: MoviesFragment;
};

export const MovieList = ({ movies }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePaging = (page: number) => {
    const { page: _currentPage, ...params } = Object.fromEntries(searchParams.entries());
    const newParams = page === 1 ? params : { ...params, page: page.toString() };
    setSearchParams(newParams);
  };

  const prevPage = movies.page && movies.page > 1 ? movies.page - 1 : undefined;
  const nextPage = movies.page && movies.totalPages && movies.page < movies.totalPages ? movies.page + 1 : undefined;

  return (
    <>
      <p className="mb-4">
        <span style={{ fontSize: 12 }}>{movies.totalResults}件ヒット。</span>
        <span style={{ fontSize: 12 }}>
          {movies.page} /{movies.totalPages}
          ページ
        </span>
        {prevPage && (
          <TextButton className="ml-2" onClick={() => handlePaging(prevPage)}>
            ＜ 前へ
          </TextButton>
        )}
        {nextPage && (
          <TextButton className="ml-2" onClick={() => handlePaging(nextPage)}>
            次へ ＞
          </TextButton>
        )}
      </p>

      <div className="grid grid-cols-3 gap-4">
        {movies.results?.map((movie) => (
          <div key={movie.id}>
            <div>
              <Link to={`/movies/${movie.id}`}>
                <img
                  alt={movie.title}
                  width="100%"
                  style={{ aspectRatio: '2 / 3' }}
                  src={movie.posterPath ? `https://image.tmdb.org/t/p/original${movie.posterPath}` : MoviePosterNoImage}
                />
              </Link>
            </div>
            <div>
              <TextLink style={{ lineHeight: 1, color: COLOR.white }} to={`/movies/${movie.id}`}>
                {movie.title}
              </TextLink>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
