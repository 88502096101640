import styled from '@emotion/styled';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { range } from 'lodash';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import * as z from 'zod';

import {
  GenderEnum,
  RegisterMailInput,
  useConfirmationMailMutation,
  useRegisterMailMutation,
} from '@/graphql/generated';
import { SimpleButton, Loading, TextLink } from '@/src/common/components';
import { Title } from '@/src/layouts/Title';
import { COLOR } from '@/src/constants';
import { useCurrentUser } from '@/src/common/hooks/useCurrentUser';

const GENDERS = Object.freeze(Object.values(GenderEnum));

const schema = z
  .object({
    password: z
      .string()
      .min(8, { message: 'パスワードは8文字以上を入力してください' })
      .max(128, { message: 'パスワードは128文字以下に設定してください' }),
    passwordConfirmation: z.string().min(1, { message: '確認用パスワードを入力してください' }),
    name: z.string().min(1, { message: 'ユーザー名を入力してください' }),
    birthday: z
      .string()
      .min(1, { message: '生年月日を入力してください' })
      .refine((d) => new Date(d) < new Date(), '現在より前の年月日を入力してください'),
    gender: z.enum(GENDERS as readonly [string, ...string[]], {
      invalid_type_error: '性別をチェックしてください',
    }),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: 'パスワードが一致しません',
    path: ['passwordConfirmation'],
  });

export const RegisterMailPage = () => {
  const { currentUser } = useCurrentUser();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [{ data: confirmationData, fetching: confirmationLoading, error: confirmationError }, confirmationMutate] =
    useConfirmationMailMutation();
  useEffect(() => {
    if (currentUser) return;
    confirmationMutate({ input: { token } });
  }, [token, currentUser, confirmationMutate]);

  const [{ data, fetching: loading, error }, mutate] = useRegisterMailMutation();

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<RegisterMailInput>({
    resolver: zodResolver(schema),
  });

  const [birthYear, setBirthYear] = useState<string>('');
  const [birthMonth, setBirthMonth] = useState<string>('');
  const [birthDay, setBirthDay] = useState<string>('');
  const yearOptions = useMemo(() => {
    setValue('birthday', '');
    const now = DateTime.now();
    return range(5, 100).map((i) => now.minus({ year: i }).year);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const monthOptions = useMemo(() => range(1, 13), []);
  const dayOptions = useMemo(() => {
    if (!birthMonth) return range(1, 32);
    const lastDay = DateTime.fromObject({
      year: birthYear ? +birthYear : 2000,
      month: +birthMonth,
    }).endOf('month').day;
    const days = range(1, lastDay + 1);
    if (!days.includes(+birthDay)) setBirthDay('');
    return days;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthYear, birthMonth]);
  useEffect(() => {
    if (!birthYear || !birthMonth || !birthDay) return;
    const birth = DateTime.fromObject({
      year: +birthYear,
      month: +birthMonth,
      day: +birthDay,
    });
    setValue('birthday', birth.toFormat('yyyy-MM-dd'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthYear, birthMonth, birthDay]);

  const onSubmit = useCallback(
    (input: RegisterMailInput) => {
      if (loading || data) return;
      mutate({ input });
    },
    [mutate, loading, data]
  );

  if (data) return <ConfirmationComplete />;
  if (currentUser) return <SignedInError />;
  if (confirmationError) return <p>{confirmationError.message}</p>;
  if (error) return <p>{error.message}</p>;
  if (!confirmationData || confirmationLoading || loading) return <Loading />;

  return (
    <>
      <Title title="本登録" />
      <h1>本登録</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-1">
          <label htmlFor="password">パスワード: </label>
        </div>
        <div className="mb-3">
          <Input
            {...register('password')}
            id="password"
            type="password"
            placeholder="パスワード"
            className="w-full p-3 rounded"
          />
          <ErrorMessage errors={errors} name="password" render={ErrorText} />
        </div>
        <div className="mb-1">
          <label htmlFor="passwordConfirmation">パスワード(確認用): </label>
        </div>
        <div className="mb-3">
          <Input
            {...register('passwordConfirmation')}
            id="passwordConfirmation"
            type="password"
            placeholder="パスワード(確認用)"
            className="w-full p-3 rounded"
          />
          <ErrorMessage errors={errors} name="passwordConfirmation" render={ErrorText} />
        </div>
        <div className="mb-1">
          <label htmlFor="name">名前: </label>
        </div>
        <div className="mb-3">
          <Input {...register('name')} id="name" type="text" placeholder="ユーザー名" className="w-full p-3 rounded" />
          <ErrorMessage errors={errors} name="name" render={ErrorText} />
        </div>
        <div className="mb-1">
          <label htmlFor="birthday">生年月日: </label>
        </div>
        <div className="mb-3">
          <select
            value={birthYear}
            onChange={(e) => setBirthYear(e.target.value)}
            className="p-3 mr-4 rounded"
            style={{ color: COLOR.black }}
          >
            <option value="" disabled>
              年
            </option>
            {yearOptions.map((year) => (
              <option key={year} value={year}>
                {year}年
              </option>
            ))}
          </select>

          <select
            value={birthMonth}
            onChange={(e) => setBirthMonth(e.target.value)}
            className="p-3 mr-4 rounded"
            style={{ color: COLOR.black }}
          >
            <option value="" disabled>
              月
            </option>
            {monthOptions.map((month) => (
              <option key={month} value={month}>
                {month}月
              </option>
            ))}
          </select>

          <select
            value={birthDay}
            onChange={(e) => setBirthDay(e.target.value)}
            className="p-3 rounded"
            style={{ color: COLOR.black }}
          >
            <option value="" disabled>
              日
            </option>
            {dayOptions.map((day) => (
              <option key={day} value={day}>
                {day}日
              </option>
            ))}
          </select>
          <ErrorMessage errors={errors} name="birthday" render={ErrorText} />
        </div>
        <div className="mb-1">
          <label htmlFor="gender">性別: </label>
        </div>
        <div className="mb-3">
          {GENDERS.map((gender) => (
            <label key={gender} className="mr-5">
              <input
                {...register('gender')}
                type="radio"
                value={gender}
                className="mr-2"
                defaultChecked={gender === GenderEnum.NoAnswer}
              />
              {t(`gender.${gender}`)}
            </label>
          ))}
          <ErrorMessage errors={errors} name="gender" render={ErrorText} />
        </div>
        <div>
          <SimpleButton type="submit">送信</SimpleButton>
        </div>
      </form>
    </>
  );
};

const ConfirmationComplete = () => (
  <>
    <p>登録が完了しました。</p>
    <p>
      <TextLink to="/">トップに戻る</TextLink>
    </p>
  </>
);

const SignedInError = () => (
  <>
    <p>ログイン済みです</p>
    <p>アカウント認証はログアウトしてから行ってください。</p>
  </>
);

const StyledErrorText = styled.p`
  color: red;
`;
const ErrorText = ({ message }: { message: string }) => <StyledErrorText className="m-2">{message}</StyledErrorText>;

const Input = styled.input`
  max-width: 600px;
  color: ${COLOR.black};
`;
