import React from 'react';
import { Route, Routes as Switch, Outlet } from 'react-router-dom';

import { Layout } from '@/src/layouts/Layout';
import { SignedGuard } from '@/src/routes/SignedGuard';
import { UnSignedGuard } from '@/src/routes/UnSignedGuard';
import { MyPage } from '@/src/features/main/pages/MyPage/MyPage';
import { TopPage } from '@/src/features/main/pages/Top/TopPage';
import { UserDetailPage } from '@/src/features/main/pages/UserDetail/UserDetailPage';
import { NotFoundPage } from '@/src/features/errors/pages/NotFoundPage';
import { AdvancedSearchMoviesPage } from '@/src/features/main/pages/AdvancedSearchMovies/AdvancedSearchMoviesPage';
import { EmotionDiagnosisPage } from '@/src/features/main/pages/EmotionDiagnosis/EmotionDiagnosisPage';
import { MovieDetailPage } from '@/src/features/main/pages/MovieDetail/MovieDetailPage';
import { PopularMoviesPage } from '@/src/features/main/pages/PopularMovies/PopularMoviesPage';
import { SearchMoviesPage } from '@/src/features/main/pages/SearchMovies/SearchMoviesPage';
import { EditAccountPage } from '@/src/features/users/pages/AccountEdit/EditAccountPage';
import { EditProfilePage } from '@/src/features/users/pages/ProfileEdit/EditProfilePage';
import { MyAccountPage } from '@/src/features/users/pages/Account/MyAccountPage';
import { ProfilePage } from '@/src/features/users/pages/Profile/ProfilePage';
import { RegisterMailPage } from '@/src/features/users/pages/RegisterMail/RegisterMailPage';
import { RegisterProviderPage } from '@/src/features/users/pages/RegisterProvider/RegisterProviderPage';
import { SignInPage } from '@/src/features/users/pages/SignIn/SignInPage';
import { SignUpPage } from '@/src/features/users/pages/SignUp/SignUpPage';
import { NewReviewsPage } from '@/src/features/main/pages/NewReviews/NewReviewsPage';
import { EmotionPage } from '@/src/features/main/pages/Emotion/RecommendMoviesPage';

const ErrorFallback = () => {
  return <>500 error</>;
};

const Root = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export const Routes = () => (
  <Switch>
    <Route path="/admins/*" element={<></>} />

    <Route path="/" element={<Root />} errorElement={<ErrorFallback />}>
      {/* トップページ */}
      <Route path="/" element={<TopPage />} />

      {/* 映画詳細 */}
      <Route path="/movies/:id" element={<MovieDetailPage />} />
      {/* 映画のレビュー一覧 */}
      <Route path="/movie_reviews/:id" element={<>映画のレビュー一覧</>} />
      {/* 新着レビュー一覧 */}
      <Route path="/new_reviews" element={<NewReviewsPage />} />

      {/* ワード検索 */}
      <Route path="/search" element={<SearchMoviesPage />} />
      {/* フィルター検索: カテゴリ一覧 */}
      <Route path="/advanced_search" element={<AdvancedSearchMoviesPage />} />
      {/* 人気映画 */}
      <Route path="/popular_movies" element={<PopularMoviesPage />} />
      {/* 気分から探す */}
      <Route path="/emotions/:emotion" element={<EmotionPage />} />

      {/* 気分診断 */}
      <Route path="/emotion_diagnosis" element={<EmotionDiagnosisPage />} />

      {/* マイページ */}
      <Route path="/my_reviews" element={<SignedGuard component={<MyPage />} />} />
      <Route path="/my_favorites" element={<SignedGuard component={<MyPage />} />} />
      {/* アカウント情報(メールアドレス) */}
      <Route path="/account" element={<SignedGuard component={<MyAccountPage />} />} />
      {/* アカウント情報編集(パスワード) */}
      <Route path="/account/edit" element={<SignedGuard component={<EditAccountPage />} />} />
      {/* プロフィール情報(名前・プロフィール・画像) */}
      <Route path="/profile" element={<SignedGuard component={<ProfilePage />} />} />
      {/* プロフィール情報編集 */}
      <Route path="/profile/edit" element={<SignedGuard component={<EditProfilePage />} />} />

      {/* ユーザー詳細 */}
      <Route path="/user_reviews/:id" element={<UserDetailPage />} />
      <Route path="/user_favorites/:id" element={<UserDetailPage />} />

      {/* ログイン */}
      <Route path="/users/sign_in" element={<UnSignedGuard component={<SignInPage />} />} />
      {/* アカウント新規登録 */}
      <Route path="/users/sign_up" element={<UnSignedGuard component={<SignUpPage />} />} />
      {/* メールアカウント登録 */}
      <Route path="/users/mail/register" element={<RegisterMailPage />} />
      {/* Googleアカウント登録 */}
      <Route path="/users/auth/register" element={<RegisterProviderPage />} />

      <Route path="/*" element={<NotFoundPage />} />
    </Route>
  </Switch>
);
