import { useCallback, useState } from 'react';
import { KaminariPageInfoFragment } from '@/graphql/generated';

export const usePageInfo = (): [
  KaminariPageInfoFragment,
  (newPageInfo: KaminariPageInfoFragment | undefined, loading: boolean) => void,
  (newPage: number) => void,
] => {
  const [pageInfo, setPageInfo] = useState<KaminariPageInfoFragment>({
    currentPage: 1,
    isFirstPage: true,
    isLastPage: true,
    isOutOfRange: false,
    limitValue: 20,
    nextPage: null,
    prevPage: null,
    totalCount: 1,
    totalPages: 1,
  });
  const setInfo = useCallback(
    (newPageInfo: KaminariPageInfoFragment | undefined, loading: boolean) => {
      if (loading || !newPageInfo) return;
      setPageInfo(newPageInfo);
    },
    [setPageInfo]
  );
  const setPage = useCallback(
    (currentPage: number) => setPageInfo((prev) => ({ ...prev, currentPage })),
    [setPageInfo]
  );

  return [pageInfo, setInfo, setPage];
};
