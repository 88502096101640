import { MovieProviderStatusEnum, useMovieDetailQuery } from '@/graphql/generated';
import { ExternalLink, SimpleOutlineButton, TextLink } from '@/src/common/components';
import { Title } from '@/src/layouts/Title';
import { COLOR, FONT_SIZE } from '@/src/constants';
import { useCurrentUser } from '@/src/common/hooks/useCurrentUser';
import { useMovieBookmark } from '@/src/features/main/hooks/useMovieBookmark';
import { FavoriteOffSvg, FavoriteOnSvg } from '@/src/images';
import { EditReview } from '@/src/features/main/pages/MovieDetail/EditReview';
import { EvaluationChart } from '@/src/features/main/components/EvaluationChart';
import { StarRating } from '@/src/common/components/StarRating/StarRating';
import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { orderBy, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { EmotionLinks } from '@/src/features/main/components/EmotionLinks';
import { MovieReview } from '@/src/features/main/components/Review';
export const MovieDetailPage = () => {
  const { id } = useParams();
  if (!id) throw new Error('id is required');

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { currentUser } = useCurrentUser();
  const [{ data, error }] = useMovieDetailQuery({ variables: { id } });
  if (!data) throw new Promise(() => null); // suspense

  const directors = useMemo(
    () => (data ? data.movieCredits.crew.filter((crew) => crew.job === 'Director') : []),
    [data]
  );
  const providersList = useMemo(() => {
    const sortedProviders = sortBy(data.movieProviders, [
      (p) => p.movieProviderKind.priority,
      (p) => p.movieProviderKind.name,
    ]);
    return [MovieProviderStatusEnum.Flatrate, MovieProviderStatusEnum.Rent, MovieProviderStatusEnum.Buy]
      .map((status) => sortedProviders.filter((provider) => provider.status === status))
      .filter((providers) => providers.length > 0);
  }, [data]);

  const videos = useMemo(
    () =>
      data
        ? orderBy(
            data.movieDetail.videos?.filter((v) => v.site === 'YouTube' && v.type === 'Trailer'),
            ['v.publishedAt'],
            ['desc']
          )
        : [],
    [data]
  );

  if (error) {
    console.error(error);
    return <>{error}</>;
  }

  return (
    <>
      <Box paddingX="16px">
        <Title title={data.movieDetail.title} />
        <Heading as="h2" color={COLOR.text.base} fontSize={FONT_SIZE.title.title1} fontWeight="700">
          {data.movieDetail.title}
        </Heading>
        <Flex mt="16px">
          <Box flex="30%" position="relative">
            <Image
              width="100%"
              height="auto"
              alt={data.movieDetail.title}
              src={
                data.movieDetail.posterPath
                  ? `https://image.tmdb.org/t/p/original${data.movieDetail.posterPath}`
                  : 'https://placehold.jp/30/3d4070/ffffff/272x408.png?text=no+image'
              }
              sx={{
                objectFit: 'cover',
                aspectRatio: '272 / 408',
              }}
            />
          </Box>
          <Flex ml="12px" flexGrow="1" flexDirection="column" justifyContent="center">
            {data.movieReviewSummary && (
              <Flex flexDirection="row" justifyContent="start" alignItems="baseline">
                <StarRating rating={data.movieReviewSummary.totalScore} />
                <Text fontSize="16px" fontWeight="600" ml="8px">
                  {data.movieReviewSummary.totalScore.toFixed(1)}
                </Text>
                <Text fontSize="12px" color={COLOR.text.middle} ml="5px">
                  ({data.movieReviewSummary.number})
                </Text>
              </Flex>
            )}
            {data.movieDetail.releaseDate && (
              <Text fontSize="12px" mt="16px" color={COLOR.text.middle}>
                {DateTime.fromISO(data.movieDetail.releaseDate).toFormat('yyyy/MM/dd')}上映
              </Text>
            )}
            <Text mt="12px" fontSize="12px">
              {data.movieDetail.movieGenreKinds.map((genre, index) => (
                <React.Fragment key={genre.id}>
                  <TextLink as="span" to={`/advanced_search?genres=${genre.id}`} whiteSpace="nowrap">
                    {genre.name}
                  </TextLink>
                  {index !== data.movieDetail.movieGenreKinds.length - 1 && ', '}
                </React.Fragment>
              ))}
            </Text>
            <Box mt="24px">
              <BookmarkButton movieId={id} />
            </Box>
          </Flex>
        </Flex>
        {data.movieReviewSummary && (
          <>
            <Heading
              as="h2"
              mt="16px"
              py="5px"
              color={COLOR.text.base}
              fontSize={FONT_SIZE.title.title2}
              fontWeight="700"
            >
              この映画にぴったりな気分
            </Heading>
            <EmotionLinks mt="12px" emotions={data.movieReviewSummary.topEmotions} />
            <Box mt="16px">
              <EvaluationChart evaluation={data.movieReviewSummary} />
            </Box>
          </>
        )}
        {currentUser && (
          <>
            <Box mt="16px">
              <EditReview
                movieId={data.movieDetail.id}
                movieTitle={data.movieDetail.title}
                currentUserReview={data.currentUserReview}
              />
            </Box>
          </>
        )}
        <Box mt="24px">
          <Text fontSize="13px" fontWeight="600" lineHeight="1" color={COLOR.text.base}>
            監督
          </Text>
          <Text mt="12px" fontSize="12px" color={COLOR.text.middle}>
            {directors.map((crew, idx) => (
              <React.Fragment key={idx}>
                <span key={crew.creditId}>{crew.name}</span>
                {idx < directors.length - 1 && <span>, </span>}
              </React.Fragment>
            ))}{' '}
          </Text>
        </Box>
        <Box mt="16px">
          <Text fontSize="13px" fontWeight="600" lineHeight="1" color={COLOR.text.base}>
            出演者
          </Text>
          <Text mt="12px" fontSize="12px" color={COLOR.text.middle}>
            {data.movieCredits.cast
              .slice(0, 5)
              .map((cast) => cast.name)
              .join(', ')}
            {data.movieCredits.cast.length > 5 && ' 他'}
          </Text>
        </Box>
      </Box>

      {!data.movieDetail.overview && videos.length === 0 && !data.movieDetail.backdropPath ? (
        <>{/* あらすじ無し・動画なし・画像なし */}</>
      ) : !data.movieDetail.overview && videos.length > 0 ? (
        <>
          {/* あらすじ無し・動画あり */}
          <iframe
            width="100%"
            style={{ aspectRatio: '16 / 9' }}
            src={`https://www.youtube.com/embed/${videos[0].key ?? ''}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </>
      ) : (
        <>
          {/* あらすじor画像どっちかあり */}
          {!!data.movieDetail.backdropPath && (
            <Box mt="32px" position="relative">
              <Image
                position="absolute"
                top="0"
                left="0"
                width="100%"
                opacity="0.5"
                zIndex="0"
                maxHeight="210px"
                src={`https://image.tmdb.org/t/p/original${data.movieDetail.backdropPath}`}
              />
            </Box>
          )}
          <Box minHeight="210px" position="relative" zIndex="1" py="24px" px="16px">
            {!!data.movieDetail.overview && (
              <>
                <Text fontSize="15px" fontWeight="600" color={COLOR.text.base}>
                  あらすじ
                </Text>
                <Text fontSize="12px" mt="12px">
                  {data.movieDetail.overview}
                </Text>
              </>
            )}
          </Box>
        </>
      )}

      <Box paddingX="16px">
        {data?.movieProviders.length > 0 && (
          <Box mt="16px">
            <Text fontSize="15px" fontWeight="600" color={COLOR.text.base}>
              配信サービス
            </Text>
            {providersList.map((providers, idx) => (
              <React.Fragment key={idx}>
                <Text mt="24px" fontSize="12px" color={COLOR.text.base}>
                  {t(`movieProviderStatus.${providers[0].status}`)}
                </Text>
                <Flex flexDirection="row" gap="8px">
                  {providers.map((provider, provideIdx) => (
                    <Box mt="12px" key={provideIdx}>
                      <ExternalLink href={provider.url}>
                        <Image
                          src={`https://image.tmdb.org/t/p/original/${provider.movieProviderKind.logoPath}`}
                          alt={provider.movieProviderKind.name}
                          width="50px"
                          height="50px"
                        />
                      </ExternalLink>
                    </Box>
                  ))}
                </Flex>
              </React.Fragment>
            ))}
          </Box>
        )}

        {!!data.movieReviews?.nodes.length && (
          <>
            <Flex mt="24px" flexDirection="row" justifyContent="space-between">
              <Text fontSize="15px" fontWeight="600" color={COLOR.text.base}>
                この映画のレビュー
              </Text>
              {data.movieReviewSummary && (
                <Flex flexDirection="row" justifyContent="start" alignItems="baseline">
                  <StarRating rating={data.movieReviewSummary.totalScore} />
                  <Text fontSize="16px" fontWeight="600" ml="8px">
                    {data.movieReviewSummary.totalScore.toFixed(1)}
                  </Text>
                  <Text fontSize="12px" color={COLOR.text.middle} ml="5px">
                    ({data.movieReviewSummary.number})
                  </Text>
                </Flex>
              )}
            </Flex>

            {data.movieReviews.nodes.slice(0, 3).map((review, index) => (
              <Box key={review.id} mt={index === 0 ? '12px' : '16px'}>
                <MovieReview
                  review={review}
                  userId={review.user.id}
                  userName={review.user.name ?? '名無しさん'}
                  userImage={review.user.image}
                />
              </Box>
            ))}

            {!data.movieReviews.pageInfo.isLastPage && (
              <SimpleOutlineButton
                mt="24px"
                w="100%"
                borderRadius="99px"
                onClick={() => navigate(`/movie_reviews/${id}`)}
              >
                レビューをもっと見る
              </SimpleOutlineButton>
            )}
          </>
        )}
      </Box>
    </>
  );
};

const BookmarkButton = ({ movieId }: { movieId: string }) => {
  const { isBookmarkByMovieId, bookmarkCountByMovieId, toggleBookmark, fetching } = useMovieBookmark({
    movieIds: [movieId],
  });

  const isBookmark = useMemo(() => isBookmarkByMovieId[movieId] ?? false, [isBookmarkByMovieId, movieId]);
  const count = useMemo(() => bookmarkCountByMovieId[movieId] ?? 0, [bookmarkCountByMovieId, movieId]);

  const onToggleBookmark = useCallback(() => toggleBookmark(movieId), [toggleBookmark, movieId]);

  return (
    <Box
      onClick={onToggleBookmark}
      w="100%"
      py="8px"
      bg={isBookmark ? '#fff' : '#403c63'}
      borderRadius="4px"
      textAlign="center"
      cursor={fetching ? 'wait' : 'pointer'}
    >
      <Flex flexDirection="row" justifyContent="center" alignItems="center" height="14px">
        <Image
          src={isBookmark ? FavoriteOnSvg : FavoriteOffSvg}
          width="14px"
          height="14px"
          cursor={fetching ? 'wait' : 'pointer'}
        />
        <Text fontSize="12px" fontWeight="600" ml="4px" color={isBookmark ? COLOR.theme.primary : undefined}>
          観たい
        </Text>
      </Flex>
      <Text fontSize="10px" color={COLOR.text.middle} height="10px">
        {count}
      </Text>
    </Box>
  );
};
