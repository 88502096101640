export type QuestionKey =
  | 'a1'
  | 'a2'
  | 'a3'
  | 'b1a'
  | 'b1b'
  | 'b2a'
  | 'b2b'
  | 'b3a'
  | 'b3b'
  | 'c1'
  | 'c2a'
  | 'c2b'
  | 'd1'
  | 'consideration'
  | 'dokidoki'
  | 'impress'
  | 'negative'
  | 'nostalgia'
  | 'positive'
  | 'quick'
  | 'sentimental';

type QuestionAnswer = {
  text: string;
  result: QuestionKey[];
};

type QuestionContent =
  | {
      type: 'question';
      text: string;
      answers: QuestionAnswer[];
    }
  | {
      type: 'result';
      text: string;
    };

type Questions = {
  [key in QuestionKey]: QuestionContent;
};

export const QUESTIONS: Questions = {
  a1: {
    type: 'question',
    text: '今日はいい感じか否か的な質問(1)',
    answers: [
      { text: 'いつも通り(1)', result: ['b1a', 'b1b'] },
      { text: 'いい感じ(1)', result: ['b2a', 'b2b'] },
      { text: 'あまり良くない(1)', result: ['b3a', 'b3b'] },
    ],
  },
  a2: {
    type: 'question',
    text: '今日はいい感じか否か的な質問(2)',
    answers: [
      { text: 'いつも通り(2)', result: ['b1a', 'b1b'] },
      { text: 'いい感じ(2)', result: ['b2a', 'b2b'] },
      { text: 'あまり良くない(2)', result: ['b3a', 'b3b'] },
    ],
  },
  a3: {
    type: 'question',
    text: '今日はいい感じか否か的な質問(3)',
    answers: [
      { text: 'いつも通り(3)', result: ['b1a', 'b1b'] },
      { text: 'いい感じ(3)', result: ['b2a', 'b2b'] },
      { text: 'あまり良くない(3)', result: ['b3a', 'b3b'] },
    ],
  },
  b1a: {
    type: 'question',
    text: '今日はスマホとかぽちぽちしながら映画を観たい？',
    answers: [
      { text: 'YES', result: ['c1'] },
      { text: 'NO', result: ['c2a', 'c2b'] },
    ],
  },
  b1b: {
    type: 'question',
    text: '今日は映画に没頭したい？',
    answers: [
      { text: 'YES', result: ['c2a', 'c2b'] },
      { text: 'NO', result: ['c1'] },
    ],
  },
  b2a: {
    type: 'question',
    text: '今日はきゅんきゅんできる映画を観たい？',
    answers: [
      { text: 'YES', result: ['positive'] },
      { text: 'NO', result: ['c2a', 'c2b'] },
    ],
  },
  b2b: {
    type: 'question',
    text: '今日はスカッとする映画を観たい？',
    answers: [
      { text: 'YES', result: ['positive'] },
      { text: 'NO', result: ['c2a', 'c2b'] },
    ],
  },
  b3a: {
    type: 'question',
    text: '今日は頭を使わないで笑えるような映画を観たい？',
    answers: [
      { text: 'YES', result: ['negative'] },
      { text: 'NO', result: ['c2a', 'c2b'] },
    ],
  },
  b3b: {
    type: 'question',
    text: '今日は癒やされるような映画を観たい？',
    answers: [
      { text: 'YES', result: ['negative'] },
      { text: 'NO', result: ['c2a', 'c2b'] },
    ],
  },
  c1: {
    type: 'question',
    text: '今日はハラハラどきどきしたい？',
    answers: [
      { text: 'YES', result: ['dokidoki'] },
      { text: 'NO', result: ['quick'] },
    ],
  },
  c2a: {
    type: 'question',
    text: '観終わった後に浸りたい気分',
    answers: [
      { text: 'YES', result: ['d1'] },
      { text: 'NO', result: ['consideration'] },
    ],
  },
  c2b: {
    type: 'question',
    text: '頭を使いたい気分？',
    answers: [
      { text: 'YES', result: ['consideration'] },
      { text: 'NO', result: ['d1'] },
    ],
  },
  d1: {
    type: 'question',
    text: 'どんな内容で浸りたい？',
    answers: [
      { text: '気持ちに共感して', result: ['sentimental'] },
      { text: '映画の世界観', result: ['nostalgia'] },
      { text: '話の内容', result: ['impress'] },
    ],
  },
  positive: {
    type: 'result',
    text: 'ポジティブな気分の人におすすめの映画へ',
  },
  negative: {
    type: 'result',
    text: 'ネガティブな気分の人におすすめの映画へ',
  },
  impress: {
    type: 'result',
    text: '感動したい気分の人におすすめの映画へ',
  },
  quick: {
    type: 'result',
    text: 'さらっと観たい気分の人におすすめの映画へ',
  },
  sentimental: {
    type: 'result',
    text: '繊細な気持ちになりたい気分の人におすすめの映画へ',
  },
  consideration: {
    type: 'result',
    text: '考えさせられる映画を観たい気分の人におすすめの映画へ',
  },
  nostalgia: {
    type: 'result',
    text: 'ノスタルジーを感じたい気分の人におすすめの映画へ',
  },
  dokidoki: {
    type: 'result',
    text: 'ハラハラドキドキしたい気分の人におすすめの映画へ',
  },
};

export const INIT_QUESTION_KEYS = ['a1', 'a2', 'a3'] as const;
