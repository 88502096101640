import styled from '@emotion/styled';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { UpdateAccountInput, useUpdateAccountMutation } from '@/graphql/generated';
import { SimpleButton, Loading } from '@/src/common/components';
import { Title } from '@/src/layouts/Title';
import { COLOR } from '@/src/constants';

const schema = z
  .object({
    oldPassword: z.string().min(1, { message: '古いパスワードを入力してください' }),
    password: z
      .string()
      .min(8, { message: 'パスワードは8文字以上を入力してください' })
      .max(128, { message: 'パスワードは128文字以下に設定してください' }),
    passwordConfirmation: z.string().min(1, { message: '確認用パスワードを入力してください' }),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: 'パスワードが一致しません',
    path: ['passwordConfirmation'],
  });

export const EditAccountPage = () => {
  const navigate = useNavigate();
  const [{ fetching: loading }, mutate] = useUpdateAccountMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<UpdateAccountInput>({
    resolver: zodResolver(schema),
  });

  const onSubmit = useCallback(
    async (input: UpdateAccountInput) => {
      if (loading) return;
      const { data, error } = await mutate({ input });
      if (error) {
        setError('oldPassword', {
          type: 'invalid',
          message: error.message,
        });
      }
      if (data?.updateAccount?.user) {
        navigate('/account');
      }
    },
    [mutate, loading, navigate, setError]
  );

  if (loading) return <Loading />;

  return (
    <>
      <Title title="ペスワードの変更" />
      <h1>パスワードの変更</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-1">
          <label htmlFor="oldPassword">古いパスワード: </label>
        </div>
        <div className="mb-3">
          <Input
            {...register('oldPassword')}
            id="oldPassword"
            type="password"
            placeholder="パスワード"
            className="w-full p-3 rounded"
          />
          <ErrorMessage errors={errors} name="oldPassword" render={ErrorText} />
        </div>
        <div className="mb-1">
          <label htmlFor="password">新しいパスワード: </label>
        </div>
        <div className="mb-3">
          <Input
            {...register('password')}
            id="password"
            type="password"
            placeholder="パスワード"
            className="w-full p-3 rounded"
          />
          <ErrorMessage errors={errors} name="password" render={ErrorText} />
        </div>
        <div className="mb-1">
          <label htmlFor="passwordConfirmation">新しいパスワード(確認用): </label>
        </div>
        <div className="mb-3">
          <Input
            {...register('passwordConfirmation')}
            id="passwordConfirmation"
            type="password"
            placeholder="パスワード(確認用)"
            className="w-full p-3 rounded"
          />
          <ErrorMessage errors={errors} name="passwordConfirmation" render={ErrorText} />
        </div>
        <div>
          <SimpleButton type="submit">送信</SimpleButton>
        </div>
      </form>
    </>
  );
};

const StyledErrorText = styled.p`
  color: red;
`;
const ErrorText = ({ message }: { message: string }) => <StyledErrorText className="m-2">{message}</StyledErrorText>;

const Input = styled.input`
  max-width: 600px;
  color: ${COLOR.black};
`;
