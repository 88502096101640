import { COLOR } from '@/src/constants';
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  closeOnOverlayClick?: boolean;
  title: string;
  children: ReactNode;
  footer: ReactNode;
  modalBodyStyle?: ModalContentProps;
};

export const CustomModal = ({
  isOpen,
  onClose,
  closeOnOverlayClick = false,
  title,
  children,
  footer,
  modalBodyStyle,
}: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={closeOnOverlayClick} scrollBehavior="inside">
    <ModalOverlay />
    <ModalContent
      w="100%"
      maxWidth={{ base: '100%', md: '600px' }}
      height={{ base: '100%', md: 'auto' }}
      maxHeight="calc(100% - 48px)"
      borderRadius={10}
      mx="12px"
      bg={COLOR.background.pale}
      overflow="hidden"
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalCloseButton />
      <ModalBody flex="1 1 auto" {...modalBodyStyle}>
        <Box color={COLOR.text.middle} lineHeight="20px" fontWeight={500}>
          {children}
        </Box>
      </ModalBody>
      <ModalFooter px={6} py={4}>
        {footer}
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default Modal;
