import { Box, useMediaQuery, Text, Flex, Image, Grid, FlexProps } from '@chakra-ui/react';
import { rgba } from 'emotion-rgba';
import { DateTime } from 'luxon';
import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  MouseEvent,
  useRef,
  PropsWithChildren,
  ComponentProps,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import tinycolor from 'tinycolor2';
import { IndexQuery, useIndexQuery } from '@/graphql/generated';
import { SimpleButton, TextLink } from '@/src/common/components';
import { Title } from '@/src/layouts/Title';
import { COLOR, BREAK_POINT, EMOTION } from '@/src/constants';
import { useCurrentUser } from '@/src/common/hooks/useCurrentUser';
import { CommentSvg, FavoriteOffCircleSvg, FavoriteOnCircleSvg, RecommendBgImage, StarSvg } from '@/src/images';
import { useMovieBookmark } from '@/src/features/main/hooks/useMovieBookmark';
import { EmotionLinks } from '@/src/features/main/components/EmotionLinks';

export const TopPage = () => {
  const navigate = useNavigate();
  const [isPC] = useMediaQuery(`(min-width: ${BREAK_POINT.sm}px)`);

  const [{ data, error }] = useIndexQuery();
  if (!data) throw new Promise(() => null); // suspense

  const popularMovies = useMemo(
    () => data.popularMovies.results?.slice(0, isPC ? 6 : 10) ?? [],
    [isPC, data.popularMovies.results]
  );
  const newReview = useMemo(() => data.newReviews.nodes[0], [data]);

  const {
    isBookmarkByMovieId,
    toggleBookmark,
    fetching: toggleBookmarkFetching,
  } = useMovieBookmark({
    movieIds: data.popularMovies.results?.map((movie) => movie.id) ?? [],
  });

  if (error) return <>Error</>;

  return (
    <>
      <Title />
      <Flex
        position="absolute"
        left="50%"
        transform="translateX(-50%)"
        width="100vw"
        height="184px"
        backgroundImage={RecommendBgImage}
        mb="36px"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        lineHeight={1.5}
      >
        <Text fontSize="20px" fontWeight="600">
          あなたの気分にぴったりな
        </Text>
        <Text fontSize="20px" fontWeight="600" mb="16px">
          映画を届けるサービス
        </Text>
        <Link to="/emotion_diagnosis">
          <SimpleButton color="primary" fontSize="sm" width="240px" height="40px" padding="16px" borderRadius="99px">
            今の気分を診断
          </SimpleButton>
        </Link>
      </Flex>
      <Box mt="220px" padding="0 16px">
        <Subtitle mt="12px" title="気分から探す" />
        <EmotionLinks mb="32px" />
        <Subtitle title="人気映画から探す" moreLinkTo="/popular_movies" />
        <Flex
          flexDirection="row"
          mb="32px"
          overflowX="auto"
          whiteSpace="nowrap"
          sx={{
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
          gap={isPC ? '2%' : '8px'}
        >
          {popularMovies.map((movie) => (
            <MovieCard
              key={movie.id}
              movieId={movie.id}
              title={movie.title}
              posterPath={movie.posterPath ?? undefined}
              reviewSummary={movie.reviewSummary}
              toggleBookmarkFetching={toggleBookmarkFetching}
              toggleBookmark={toggleBookmark}
              isBookmark={isBookmarkByMovieId[movie.id]}
            />
          ))}
        </Flex>

        {newReview && (
          <>
            <Subtitle title="新着レビュー" moreLinkTo="/new_reviews" />
            <Flex bg={COLOR.background.pale} borderRadius="4px" p="12px" mb="32px">
              <Box
                width="100px"
                flexShrink="0"
                onClick={() => navigate(`/movies/${newReview.movie.id}`)}
                cursor="pointer"
              >
                <Image
                  width="100%"
                  height="auto"
                  alt={newReview.movie.title}
                  src={
                    newReview.movie.posterPath
                      ? `https://image.tmdb.org/t/p/original${newReview.movie.posterPath}`
                      : 'https://placehold.jp/30/3d4070/ffffff/272x408.png?text=no+image'
                  }
                  sx={{
                    objectFit: 'cover',
                    aspectRatio: '272 / 408',
                  }}
                />
              </Box>

              <Box ml="12px" flexGrow="1">
                <Text fontSize="10px" color={COLOR.text.deep}>
                  {DateTime.fromISO(newReview.createdAt as string).toFormat('yyyy/MM/dd')}
                </Text>
                <Text mt="10px" fontSize="13px" fontWeight="600">
                  {newReview.title}
                </Text>
                <Text
                  mt="10px"
                  fontSize="10px"
                  color={COLOR.text.middle}
                  onClick={() => navigate(`/user_reviews/${newReview.user.id}`)}
                  cursor="pointer"
                >
                  {newReview.user.name}
                </Text>
                <Text mt="10px" fontSize="12px">
                  {newReview.totalScore}
                </Text>
                <ReadMoreText>{newReview.reviewText}</ReadMoreText>
              </Box>
            </Flex>
          </>
        )}

        <Subtitle title="ジャンルから探す" />
        <Grid gap="15px" mb="32px" gridTemplateColumns="repeat(2, 1fr)" width="100%">
          {data.movieGenreKinds.map((genre) => (
            <Box
              key={genre.id}
              as={Link}
              to={`/advanced_search?genres=${genre.id}`}
              borderRadius="5px"
              background={COLOR.background.pale}
              color={COLOR.white}
              padding="10px"
              textAlign="center"
              _hover={{
                background: tinycolor(COLOR.background.pale).lighten(10).toString(),
              }}
            >
              {genre.name}
            </Box>
          ))}
        </Grid>
      </Box>
    </>
  );
};

function ReadMoreText({
  lineHeight = 10,
  maxLine = 3,
  children,
}: PropsWithChildren<{ lineHeight?: number; maxLine?: number }>) {
  const textRef = useRef<HTMLParagraphElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isClamp, setIsClamp] = useState(false);

  useEffect(() => {
    if (textRef.current === null) return;

    const elementHeight = textRef.current.clientHeight;
    const expectedHeight = lineHeight * maxLine;
    if (elementHeight > expectedHeight) {
      setIsClamp(true);
    }
  }, [children, lineHeight, maxLine]);

  const toggleLines = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box mt="12px" fontSize="10px" color={COLOR.text.middle}>
      <Text
        ref={textRef}
        sx={
          isExpanded
            ? {}
            : {
                display: '-webkit-box',
                '-webkit-box-orient': 'vertical',
                '-webkit-line-clamp': '3',
                overflow: 'hidden',
                'text-overflow': 'ellipsis',
              }
        }
      >
        {children}
      </Text>
      {isClamp && (
        <Text onClick={toggleLines} color={COLOR.text.link} textAlign="right">
          {isExpanded ? '閉じる' : '続きを読む'}
        </Text>
      )}
    </Box>
  );
}

const MovieCard = ({
  movieId,
  title,
  posterPath,
  reviewSummary,
  ...bookmarkButtonProps
}: ComponentProps<typeof BookmarkButton> & {
  movieId: string;
  title: string;
  posterPath?: string;
  reviewSummary: NonNullable<IndexQuery['popularMovies']['results']>[number]['reviewSummary'];
}) => {
  const navigate = useNavigate();
  const [isPC] = useMediaQuery(`(min-width: ${BREAK_POINT.sm}px)`);

  const { currentUser } = useCurrentUser();

  return (
    <Box flex={`0 0 ${isPC ? '15%' : '27%'}`} position="relative">
      <Image
        width="100%"
        height="auto"
        alt={title}
        src={
          posterPath
            ? `https://image.tmdb.org/t/p/original${posterPath}`
            : 'https://placehold.jp/30/3d4070/ffffff/272x408.png?text=no+image'
        }
        sx={{
          objectFit: 'cover',
          aspectRatio: '272 / 408',
        }}
        onClick={() => navigate(`/movies/${movieId}`)}
        cursor="pointer"
      />
      <Flex
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        fontSize="10px"
        py="11px"
        px="4px"
        bg={rgba(COLOR.white, 0.1)}
      >
        <Image src={StarSvg} />
        <Text ml="4px">{reviewSummary?.totalScore ?? '-'}</Text>
        <Image ml="8px" src={CommentSvg} />
        <Text ml="4px">{reviewSummary?.number ?? 0}</Text>
      </Flex>
      {currentUser && <BookmarkButton movieId={movieId} {...bookmarkButtonProps} />}
      {reviewSummary && (
        <Flex position="absolute" bottom="50px" left="8px" flexDirection="row">
          {reviewSummary.topEmotions.map((emotion) => (
            <Image key={emotion} src={EMOTION[emotion].icon} width="20px" height="20px" />
          ))}
        </Flex>
      )}
    </Box>
  );
};

const BookmarkButton = ({
  movieId,
  isBookmark,
  toggleBookmarkFetching,
  toggleBookmark,
}: {
  movieId: string;
  isBookmark: boolean;
  toggleBookmarkFetching: boolean;
  toggleBookmark: (movieId: string) => void;
}) => {
  const onClickBookmarkButton = useCallback(
    (event: MouseEvent<HTMLImageElement, globalThis.MouseEvent>) => {
      event.stopPropagation();
      if (toggleBookmarkFetching) return;

      toggleBookmark(movieId);
    },
    [toggleBookmarkFetching, toggleBookmark, movieId]
  );

  return (
    <Box position="absolute" top="4px" right="4px">
      <Image
        src={isBookmark ? FavoriteOnCircleSvg : FavoriteOffCircleSvg}
        width="37px"
        height="37px"
        onClick={onClickBookmarkButton}
        cursor={toggleBookmarkFetching ? 'wait' : 'pointer'}
      />
    </Box>
  );
};

const Subtitle = ({ title, moreLinkTo, ...flexProps }: FlexProps & { title: string; moreLinkTo?: string }) => (
  <Flex flexDirection="row" justifyContent="space-between" width="100%" mb="18px" {...flexProps}>
    <Box color={COLOR.grayLight}>{title}</Box>
    {moreLinkTo && (
      <Box>
        <TextLink to={moreLinkTo}>もっと見る ＞</TextLink>
      </Box>
    )}
  </Flex>
);
