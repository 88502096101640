import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react';

import { Footer } from '@/src/layouts/Footer';
import { Header } from '@/src/layouts/Header';
import { COLOR, FONT_FAMILY } from '@/src/constants';

export const Layout = ({ children }: PropsWithChildren<object>) => {
  return (
    <>
      <Global styles={globalStyle} />
      <Header />
      <Main>{children}</Main>
      <Footer />
    </>
  );
};

const globalStyle = css`
  body {
    overflow-x: hidden;
    color: ${COLOR.white};
    font-family: ${FONT_FAMILY};
  }
  #app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-width: 100vw;
    min-height: 100vh;
    background: ${COLOR.background.base};
  }
`;

const Main = styled.main`
  min-height: calc(100vh - 253px);
  max-width: 1000px;
  width: 100%;
  padding: 16px 0 64px 0;
`;
