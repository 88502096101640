import styled from '@emotion/styled';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { SignInInput, useSignInMutation } from '@/graphql/generated';
import { SimpleButton, Loading, TextButton, TextLink } from '@/src/common/components';
import { Title } from '@/src/layouts/Title';
import { COLOR } from '@/src/constants';
import { CsrfTokenInput } from '@/src/features/users/shared/CsrfTokenInput';

const schema = z.object({
  email: z
    .string()
    .min(1, { message: 'メールアドレスを入力してください' })
    .email({ message: '正しいメールアドレスを入力してください' }),
  password: z
    .string()
    .min(1, { message: 'パスワードを入力してください' })
    .min(8, { message: 'パスワードは8文字以上です' })
    .max(128, { message: 'パスワードは128文字以下です' }),
});

export const SignInPage = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SignInInput>({
    resolver: zodResolver(schema),
  });
  const [{ fetching: loading }, mutate] = useSignInMutation();

  if (loading) return <Loading />;

  return (
    <>
      <Title title="ログイン" />
      <h1>ログイン</h1>
      <form
        onSubmit={handleSubmit(async (input) => {
          const { data, error } = await mutate({ input });
          if (error) {
            setError('email', {
              type: 'invalid',
              message: error.message,
            });
            setError('password', {
              type: 'invalid',
              message: error.message,
            });
          }
          if (data?.signIn?.user) {
            navigate('/');
          }
        })}
      >
        <div className="mb-1">
          <label htmlFor="email">メールアドレス: </label>
        </div>
        <div className="mb-3">
          <Input
            {...register('email')}
            id="email"
            type="text"
            placeholder="メールアドレス"
            className="w-full p-3 rounded"
          />
          <ErrorMessage errors={errors} name="email" render={ErrorText} />
        </div>
        <div className="mb-1">
          <label htmlFor="password">パスワード: </label>
        </div>
        <div className="mb-3">
          <Input
            {...register('password')}
            id="password"
            type="password"
            placeholder="パスワード"
            className="w-full p-3 rounded"
          />
          <ErrorMessage errors={errors} name="password" render={ErrorText} />
        </div>
        <div>
          <SimpleButton type="submit">送信</SimpleButton>
        </div>
      </form>
      <form action="/users/auth/google_oauth2" method="post">
        <CsrfTokenInput />
        <p>
          <button type="submit">
            <TextButton>Googleでログイン</TextButton>
          </button>
        </p>
      </form>
      <p>
        <TextLink to="/users/sign_up">新規登録はこちら</TextLink>
      </p>
    </>
  );
};

const StyledErrorText = styled.p`
  color: red;
`;
const ErrorText = ({ message }: { message: string }) => <StyledErrorText className="m-2">{message}</StyledErrorText>;

const Input = styled.input`
  max-width: 600px;
  color: ${COLOR.black};
`;
