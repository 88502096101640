import { useBookmarkMoviesQuery, useUpdateMovieBookmarkMutation } from '@/graphql/generated';
import { useCallback, useLayoutEffect, useState } from 'react';

type IsBookmarkByMovieId = { [x: string]: boolean };
type BookmarkCountByMovieId = { [x: string]: number };

export const useMovieBookmark = ({ movieIds }: { movieIds: string[] }) => {
  const [{ data }] = useBookmarkMoviesQuery({ variables: { movieIds } });
  const [{ fetching }, updateMovieBookmark] = useUpdateMovieBookmarkMutation();
  const [refetching, setRefetching] = useState(false);

  if (!data) throw new Promise(() => null); // suspense

  const [isBookmarkByMovieId, setIsBookmarkByMovieId] = useState<IsBookmarkByMovieId>(
    Object.fromEntries(data.bookmarkMovies.map((movie) => [movie.id, movie.isBookmark ?? false]))
  );
  const [bookmarkCountByMovieId, setBookmarkCountByMovieId] = useState<BookmarkCountByMovieId>(
    Object.fromEntries(data.bookmarkMovies.map((movie) => [movie.id, movie.counts]))
  );

  useLayoutEffect(() => {
    setIsBookmarkByMovieId(
      Object.fromEntries(data.bookmarkMovies.map((movie) => [movie.id, movie.isBookmark ?? false]))
    );
    setBookmarkCountByMovieId(Object.fromEntries(data.bookmarkMovies.map((movie) => [movie.id, movie.counts])));
    setRefetching(false);
  }, [data.bookmarkMovies]);

  const toggleBookmark = useCallback(
    (movieId: string) => {
      if (fetching || refetching) return;

      const isBookmark = isBookmarkByMovieId[movieId];
      setIsBookmarkByMovieId((prev) => ({ ...prev, [movieId]: !isBookmark }));
      setBookmarkCountByMovieId((prev) => ({ ...prev, [movieId]: isBookmark ? prev[movieId] - 1 : prev[movieId] + 1 }));
      setRefetching(true);
      updateMovieBookmark({
        input: { movieId, isBookmark: !isBookmark },
      });
    },
    [fetching, refetching, isBookmarkByMovieId, updateMovieBookmark]
  );

  return {
    isBookmarkByMovieId,
    bookmarkCountByMovieId,
    toggleBookmark,
    fetching: fetching || refetching,
  };
};
