import React from 'react';
import { Tag, TagProps } from '@chakra-ui/react';
import { COLOR } from '@/src/constants';

export const RequiredLabel = ({ ...tagProps }: TagProps) => (
  <Tag
    px="8px"
    py="0px"
    height="21px"
    fontSize="12px"
    fontWeight="600"
    color={COLOR.text.base}
    bg={COLOR.theme.primary}
    {...tagProps}
  >
    必須
  </Tag>
);
