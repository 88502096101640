import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '@/src/common/hooks/useCurrentUser';

export const SignedGuard = ({ component }: { component: React.ReactNode }) => {
  const navigate = useNavigate();

  const { currentUser } = useCurrentUser();
  useEffect(() => {
    if (!currentUser) navigate('/users/sign_in');
  }, [currentUser, navigate]);

  return <>{component}</>;
};
