import { Button, ButtonProps } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';
import tinycolor from 'tinycolor2';
import { COLOR, FONT_SIZE } from '@/src/constants';

type Props = {
  color?: keyof (typeof COLOR)['button'];
  radius?: keyof typeof BORDER_RADIUS;
  fontSize?: keyof (typeof FONT_SIZE)['button'];
};

const BORDER_RADIUS = {
  xl: '99px',
  lg: '12px',
  md: '8px',
  sm: '4px',
};

export const SimpleButton = ({
  children,
  color = 'primary',
  radius = 'sm',
  fontSize = 'lg',
  isDisabled,
  isLoading,
  ...reset
}: PropsWithChildren<Omit<ButtonProps, 'color' | 'fontSize'> & Props>) => {
  return (
    <Button
      fontSize={FONT_SIZE.button[fontSize]}
      borderRadius={BORDER_RADIUS[radius]}
      color={COLOR.white}
      bgColor={isLoading || isDisabled ? tinycolor(COLOR.button[color]).darken(10).toString() : COLOR.button[color]}
      _hover={{
        backgroundColor: tinycolor(COLOR.button[color]).darken(10).toString(),
      }}
      isLoading={isLoading}
      isDisabled={isDisabled}
      {...reset}
    >
      {children}
    </Button>
  );
};

export const SimpleOutlineButton = ({
  children,
  color = 'primary',
  radius = 'sm',
  fontSize = 'lg',
  ...reset
}: PropsWithChildren<Omit<ButtonProps, 'color' | 'fontSize'> & Props>) => {
  return (
    <Button
      variant="outline"
      fontSize={FONT_SIZE.button[fontSize]}
      borderRadius={BORDER_RADIUS[radius]}
      color={COLOR.white}
      borderColor={COLOR.button[color]}
      _hover={{
        backgroundColor: tinycolor(COLOR.background.base).lighten(10).toString(),
      }}
      {...reset}
    >
      {children}
    </Button>
  );
};
