import { ReactNode, useCallback } from 'react';

import { CustomModal } from './CustomModal';
import { Button, Flex } from '@chakra-ui/react';
import React from 'react';
import { COLOR } from '@/src/constants';
import tinycolor from 'tinycolor2';

type Props = {
  children: ReactNode;
  isLoading?: boolean;
  isDisabled?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onExec: () => void | Promise<void>;
  title?: string;
  closeText?: string;
  execText?: string;
  variant?: 'danger';
};

export const ConfirmModal = ({
  children,
  isOpen,
  onClose,
  title = '確認',
  closeText = 'キャンセル',
  execText = 'OK',
  onExec,
  variant,
  isLoading,
  isDisabled,
}: Props) => {
  const handleExec = useCallback(async () => {
    if (isLoading === undefined) {
      onExec();
    } else {
      await onExec();
    }
    onClose();
  }, [onExec, onClose, isLoading]);

  return (
    <CustomModal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <Flex justifyContent="flex-end" alignItems="center" columnGap="10px">
          <Button
            variant="outline"
            color={COLOR.white}
            _hover={{ backgroundColor: 'rgba(255,255,255,0.2)' }}
            onClick={onClose}
            isDisabled={isDisabled}
          >
            {closeText}
          </Button>
          <Button
            variant={variant}
            backgroundColor={variant === 'danger' ? COLOR.status.attention : COLOR.theme.primary}
            _hover={{
              backgroundColor: tinycolor(variant === 'danger' ? COLOR.status.attention : COLOR.theme.primary)
                .darken(10)
                .toString(),
            }}
            onClick={handleExec}
            isLoading={isLoading}
            isDisabled={isDisabled}
          >
            {execText}
          </Button>
        </Flex>
      }
    >
      {children}
    </CustomModal>
  );
};
