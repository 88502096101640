import styled from '@emotion/styled';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { SignUpMailInput, useSignUpMailMutation } from '@/graphql/generated';
import { SimpleButton, Loading, TextButton, TextLink } from '@/src/common/components';
import { Title } from '@/src/layouts/Title';
import { COLOR } from '@/src/constants';
import { CsrfTokenInput } from '@/src/features/users/shared/CsrfTokenInput';

const schema = z.object({
  email: z
    .string()
    .min(1, { message: 'メールアドレスを入力してください' })
    .email({ message: '正しいメールアドレスを入力してください' }),
});

export const SignUpPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<SignUpMailInput>({
    resolver: zodResolver(schema),
  });
  const [{ data, fetching: loading }, mutate] = useSignUpMailMutation();

  if (loading) return <Loading />;
  if (data) return <SignUpMailSent />;

  return (
    <>
      <Title title="新規登録" />
      <h1>新規登録</h1>
      <form
        onSubmit={handleSubmit(async (input) => {
          const { error } = await mutate({ input });
          if (error) {
            setError('email', {
              type: 'invalid',
              message: error.message,
            });
          }
        })}
      >
        <div className="mb-1">
          <label htmlFor="email">email: </label>
        </div>
        <div className="mb-3">
          <EmailInput
            id="email"
            type="email"
            placeholder="your_mail@example.com"
            className="w-full p-3 rounded"
            {...register('email')}
          />
          <ErrorMessage errors={errors} name="email" render={ErrorText} />
        </div>
        <div>
          <SimpleButton type="submit">送信</SimpleButton>
        </div>
      </form>
      <p>
        <form action="/users/auth/google_oauth2" method="post">
          <CsrfTokenInput />
          <button type="submit">
            <TextButton>Googleで登録</TextButton>
          </button>
        </form>
      </p>
      <p>
        <TextLink to="/users/sign_in">ログインはこちら</TextLink>
      </p>
    </>
  );
};

const SignUpMailSent = () => (
  <>
    <p>メールを送信しました。</p>
    <p>メール内にリンクから本登録にお進みください</p>
    <p>
      <TextLink to="/">トップに戻る</TextLink>
    </p>
  </>
);

const StyledErrorText = styled.p`
  color: red;
`;
const ErrorText = ({ message }: { message: string }) => <StyledErrorText className="m-2">{message}</StyledErrorText>;

const EmailInput = styled.input`
  max-width: 600px;
  color: ${COLOR.black};
`;
