import React, { PropsWithChildren } from 'react';
import { createUltimatePagination, PaginationComponentProps } from 'react-ultimate-pagination';
import { KaminariPageInfoFragment } from '@/graphql/generated';
import { Button } from '@chakra-ui/react';

const Page = (props: PaginationComponentProps) => (
  <Button
    variant={props.isActive ? 'solid' : 'outline'}
    color={props.isActive ? 'white' : 'gray'}
    fontWeight="semibold"
    py="2"
    px="4"
    mx="1"
    border={props.isActive ? 'none' : '1px solid gray.400'}
    borderRadius="md"
    onClick={props.isActive ? undefined : props.onClick}
    disabled={props.isActive}
  >
    {props.value}
  </Button>
);

const Ellipsis = (_props: PaginationComponentProps) => (
  <Button variant="solid" color="white" fontWeight="semibold" py="2" px="4" disabled>
    ...
  </Button>
);

const firstPageLink = (disabled: boolean) => {
  const component = (props: PaginationComponentProps) =>
    disabled ? (
      <></>
    ) : (
      <Button
        variant="outline"
        color="gray"
        fontWeight="semibold"
        py="2"
        mx="1"
        px="4"
        border="1px solid gray.400"
        borderRadius="md"
        onClick={props.onClick}
      >
        ≪
      </Button>
    );
  component.displayName = 'FirstPageLink';
  return component;
};

const previousPageLink = (disabled: boolean) => {
  const component = (props: PaginationComponentProps) =>
    disabled ? (
      <></>
    ) : (
      <Button
        variant="outline"
        color="gray"
        fontWeight="semibold"
        py="2"
        mx="1"
        px="4"
        border="1px solid gray.400"
        borderRadius="md"
        onClick={props.onClick}
      >
        &#60;
      </Button>
    );
  component.displayName = 'PreviousPageLink';
  return component;
};

const nextPageLink = (disabled: boolean) => {
  const component = (props: PaginationComponentProps) =>
    disabled ? (
      <></>
    ) : (
      <Button
        variant="outline"
        color="gray"
        fontWeight="semibold"
        py="2"
        mx="1"
        px="4"
        border="1px solid gray.400"
        borderRadius="md"
        onClick={props.onClick}
      >
        &#62;
      </Button>
    );
  component.displayName = 'NextPageLink';
  return component;
};

const lastPageLink = (disabled: boolean) => {
  const component = (props: PaginationComponentProps) =>
    disabled ? (
      <></>
    ) : (
      <Button
        variant="outline"
        color="gray"
        fontWeight="semibold"
        py="2"
        mx="1"
        px="4"
        border="1px solid gray.400"
        borderRadius="md"
        onClick={props.onClick}
      >
        ≫
      </Button>
    );
  component.displayName = 'LastPageLink';
  return component;
};

const Wrapper = (props: PropsWithChildren) => <div className="pagination">{props.children}</div>;

export const Pagination = ({
  pageInfo,
  onChange,
}: {
  pageInfo: KaminariPageInfoFragment;
  onChange: (newPage: number) => void;
}) => {
  const PaginationComponent = createUltimatePagination({
    itemTypeToComponent: {
      PAGE: Page,
      ELLIPSIS: Ellipsis,
      FIRST_PAGE_LINK: firstPageLink(pageInfo.isFirstPage),
      PREVIOUS_PAGE_LINK: previousPageLink(pageInfo.isFirstPage),
      NEXT_PAGE_LINK: nextPageLink(pageInfo.isLastPage),
      LAST_PAGE_LINK: lastPageLink(pageInfo.isLastPage),
    },
    WrapperComponent: Wrapper,
  });

  if (pageInfo.totalPages <= 1) return <></>;

  return (
    <PaginationComponent totalPages={pageInfo.totalPages} currentPage={pageInfo.currentPage} onChange={onChange} />
  );
};
