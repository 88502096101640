import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  ja: {
    translation: {
      movieProviderStatus: {
        flatrate: '定額',
        rent: 'レンタル',
        buy: '購入',
      },
      gender: {
        male: '男性',
        female: '女性',
        noAnswer: '未回答',
      },
      recommend: {
        consideration: '考えさせられる映画を観たい気分',
        dokidoki: 'ハラハラドキドキしたい気分',
        impress: '感動したい気分',
        negative: 'ネガティブな気分',
        nostalgia: 'ノスタルジーを感じたい気分',
        positive: 'ポジティブな気分',
        quick: 'さらっと観たい気分',
        sentimental: '繊細な気持ちになりたい気分',
      },
    },
  },
};

i18n.use(initReactI18next).init({
  debug: import.meta.env.DEV,
  resources,
  lng: 'ja',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});
