export const COLOR = {
  theme: {
    primary: '#6638E8',
    primaryPale: '#A38DE0',
    secondary: '#129C9C',
    reviewYellow: '#FFE03F',
    border: '#46436A',
  },
  text: {
    base: '#E1E3E5',
    middle: '#C2BBD3',
    deep: '#90849E',
    link: '#A38DE0',
    placeholder: '#312847',
  },
  background: {
    base: '#1E1A48',
    bright: '#FFFFFF',
    deep: '#0D0B28',
    pale: '#342F64',
    form: 'rgba(255, 255, 255, 0.2)',
  },
  status: {
    attention: '#DB2525',
    success: '#69C021',
  },
  button: {
    primary: '#6638E8',
    secondary: '#129C9C',
    header: '#14AEAE',
    danger: '#DB2525',
  },
  // deprecated
  black: '#000',
  white: '#FFFFFF',
  grayLight: '#F4F4F4',
  gray: '#E0E0E0',
  yellow: '#FFD330',
  purpleLight: '#381C6B',
  border: {
    purple: '#806f9d',
  },
  gradients: {
    purple: 'linear-gradient(180deg, #28124B 0%, #090021 71.87%)',
  },
} as const;

type ValueOf<T> = T[keyof T];
type NestedValueOf<T> =
  | ValueOf<T>
  | ValueOf<{
      [K in keyof T]: T[K] extends object ? NestedValueOf<T[K]> : never;
    }>;

export type Color = Extract<NestedValueOf<typeof COLOR>, string>;
