import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import { KaminariPageInfoFragment, ReviewFragment, useUserDetailQuery } from '@/graphql/generated';
import { Loading, TextLink } from '@/src/common/components';
import { Title } from '@/src/layouts/Title';
import { Pagination } from '@/src/common/components/Pagination/Pagination';
import { usePageInfo } from '@/src/common/hooks/usePageInfo';
import { EvaluationChart } from '@/src/features/main/components/EvaluationChart';

export const UserDetailPage = () => {
  const { id: userId } = useParams();

  if (!userId) return <>404 Not Found</>;

  return <Content userId={userId} />;
};

const Content = ({ userId }: { userId: string }) => {
  const { t } = useTranslation();
  const [pageInfo, setPageInfo, setPage] = usePageInfo();

  const [{ data, fetching: loading, error }] = useUserDetailQuery({
    variables: {
      id: userId,
      page: pageInfo.currentPage,
    },
  });

  useEffect(() => {
    const newPageInfo: KaminariPageInfoFragment | undefined = data?.userReviews?.pageInfo;
    setPageInfo(newPageInfo, loading);
  }, [data, loading, setPageInfo]);

  if (error) return <>{error.message}</>;
  if (loading) return <Loading />;
  if (!data?.user) return <></>;

  return (
    <>
      <Title title={data.user.name ? `ユーザー詳細ページ ${data.user.name}` : 'ユーザー詳細ページ'} />
      <h1 className="mb-3">ユーザー詳細ページ</h1>
      <h2>プロフィール</h2>
      <div className="mb-3">
        <p>name: {data.user.name}</p>
        <p>profile: {data.user.profile || '未設定'}</p>
        <p>
          image:
          {data.user.image ? <img src={data.user.image} /> : '未設定'}
        </p>
      </div>
      <h2>レビュー一覧({data.userReviews.pageInfo.totalCount})</h2>
      <div>
        {data.userReviews.nodes.map((node, index) => {
          const { movie, ...rest } = node;
          const review: ReviewFragment = rest;

          return (
            <div key={review.id}>
              {index !== 0 && <hr />}
              <p>
                <TextLink to={`/movies/${movie.id}`}>{movie.title}</TextLink>
              </p>
              <p>総合評価: {review.totalScore}</p>
              <p>個別評価:</p>
              <EvaluationChart evaluation={review} />
              <p>
                気分:
                {review.emotions.map((emotionType) => t(`recommend.${emotionType}`)).join(', ')}
              </p>
            </div>
          );
        })}
      </div>
      <div className="flex justify-center">
        <Pagination
          pageInfo={pageInfo}
          onChange={(newPage: number) => {
            animateScroll.scrollToTop({ duration: 100 });
            setPage(newPage);
          }}
        />
      </div>
    </>
  );
};
