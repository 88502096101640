import * as ActiveStorage from '@rails/activestorage';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from '@/src/App';

ActiveStorage.start();

document.addEventListener('DOMContentLoaded', () => {
  const container = document.createElement('div');
  container.id = 'app';
  document.body.appendChild(container);
  const root = createRoot(container);
  root.render(<App />);
});
