import { EmotionEnum } from '@/graphql/generated';
import { COLOR, FONT_SIZE } from '@/src/constants';
import { ALL_EMOTIONS, EMOTION } from '@/src/constants';

import { Grid, Flex, Image, Text, GridProps } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import tinycolor from 'tinycolor2';

export const EmotionLinks = ({
  emotions = ALL_EMOTIONS,
  ...gridProps
}: { emotions?: readonly EmotionEnum[] } & GridProps) => (
  <Grid gap="15px" gridTemplateColumns="repeat(2, 1fr)" width="100%" {...gridProps}>
    {emotions.map((emotionType) => (
      <Flex
        key={emotionType}
        as={Link}
        to={`${EMOTION[emotionType].link}`}
        borderRadius="5px"
        background={COLOR.background.pale}
        color={COLOR.white}
        padding="10px"
        _hover={{
          background: tinycolor(COLOR.background.pale).lighten(10).toString(),
        }}
        justifyContent="start"
        alignItems="center"
      >
        <Image width="26px" height="26px" src={EMOTION[emotionType].icon} alt={EMOTION[emotionType].text} />
        <Text ml="8px" color={COLOR.text.middle} fontSize={FONT_SIZE.text.middle}>
          {EMOTION[emotionType].text}
        </Text>
      </Flex>
    ))}
  </Grid>
);
