import { createClient, cacheExchange, mapExchange, CombinedError, fetchExchange } from 'urql';
import { csrfToken } from '@/src/config/Rails';

export const initUrqlClient = (onError?: (error: CombinedError) => void) => {
  return createClient({
    url: import.meta.env.VITE_GRAPHQL_ENDPOINT,
    requestPolicy: 'cache-and-network',
    fetchOptions: {
      credentials: 'include',
      headers: { 'X-CSRF-Token': csrfToken },
    },
    suspense: true,
    exchanges: [
      mapExchange({
        onError(error, _operation) {
          onError?.(error);
        },
      }),
      cacheExchange,
      fetchExchange,
    ],
  });
};

export * from 'urql';
export { Provider as UrqlProvider } from 'urql';
